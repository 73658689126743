// eslint-disable-next-line import/no-cycle
import { ChainInfoMap, NetworkType } from './chainType'

const INFURA_KEY = '60c5ee4578034f80899d9faecd9005db'
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

export const ChainTxUrl = 'https://testnet.blastscan.io/tx'
export const ChainAddressUrl = 'https://testnet.blastscan.io/address'

/**
 * @desc List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  BLAST_SEPPLIA = 168587773,
}

export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  /* 
  https://blast-sepolia.blockpi.network/v1/rpc/public
  https://sepolia.blast.io
  https://blast-sepolia.drpc.org
  */
  [SupportedChainId.BLAST_SEPPLIA]: `https://blast-sepolia.g.alchemy.com/v2/T3mE7YZJZwTA4oZJo4JvLQjMIxU7FRof`,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.BLAST_SEPPLIA]: 'Blast Sepolia',
}

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.BLAST_SEPPLIA]: {
    networkType: NetworkType.L2,
    bridge: '0xc644cc19d2A9388b71dd1dEde07cFFC73237Dca8',
    docs: 'https://docs.blast.io',
    explorer: 'https://testnet.blastscan.io',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Blast Sepolia',
    logoUrl:
      'https://assets-global.website-files.com/65a6baa1a3f8ed336f415cb4/65a6cc95aae1066cf96d497d_Logo%20Black%20on%20Yellow%20Background%402x-p-500.png',
    nativeCurrency: {
      name: 'Blast Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
}

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */

export const L2_CHAIN_IDS = [SupportedChainId.BLAST_SEPPLIA] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number',
) as SupportedChainId[]
