import React from 'react'
import setIcon from "assets/svg/setIcon.svg"

const Icon = (props) => {
    return (
        <img src={setIcon} {...props} alt="" />
    )
}

export default Icon
