import React from 'react'
import { withRouter } from 'react-router'
import { useWeb3React } from 'web3-react-core'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
// import { Menu as UikitMenu } from './index'
import UikitMenu from './UikitMenu'
import useCurrentBalance from '../../hooks/useCurrentBalance'
import { HashPowerTokenProvider } from '../../contexts'

const whiteRouterList = ['/dashboard', '/faucet']

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  const balance = useCurrentBalance()
  const { location } = props
  return (
    <>
      {location && whiteRouterList.includes(location.pathname) ? (
        <UikitMenu
          account={account}
          login={login}
          logout={logout}
          isDark={isDark}
          toggleTheme={toggleTheme}
          // currentLang={currentLanguage.code}
          // langs={languageList}
          // setLang={setLanguage}
          balance={balance}
          {...props}
        />
      ) : (
        <HashPowerTokenProvider>
          <UikitMenu
            account={account}
            login={login}
            logout={logout}
            isDark={isDark}
            toggleTheme={toggleTheme}
            // currentLang={currentLanguage.code}
            // langs={languageList}
            // setLang={setLanguage}
            balance={balance}
            {...props}
          />
        </HashPowerTokenProvider>
      )}
    </>
  )
}

export default withRouter(Menu)
