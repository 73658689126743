/* eslint-disable @typescript-eslint/no-empty-interface */
import { createGlobalStyle } from 'styled-components'
import { MFTheme } from 'components/uikit'

declare module 'styled-components' {
  export interface DefaultTheme extends MFTheme {}
}

const GlobalStyle = createGlobalStyle`
 
  @font-face {
    font-family: "Heebo Balck";
    src: url("/fonts/heebo/Heebo-Black.ttf");
  }
  @font-face {
    font-family: "Heebo Medium";
    src: url("/fonts/heebo/Heebo-Medium.ttf");
  }
  @font-face {
    font-family: "Heebo Bold";
    src: url("/fonts/heebo/Heebo-Bold.ttf");
  }
  @font-face {
    font-family: "Heebo ExtraBold";
    src: url("/fonts/heebo/Heebo-ExtraBold.ttf");
  }
  @font-face {
    font-family: "Heebo Light";
    src: url("/fonts/heebo/Heebo-Light.ttf");
  }
  @font-face {
    font-family: "Heebo Regular";
    src: url("/fonts/heebo/Heebo-Regular.ttf");
  }
  @font-face {
    font-family: "Heebo Thin";
    src: url("/fonts/heebo/Heebo-Thin.ttf");
  }

  * {
    font-family: "Heebo Medium";
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
  
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
