import React from 'react'
import { ModalProvider } from 'components/uikit'
import { Web3ReactProvider } from 'web3-react-core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { getLibrary } from 'utils/web3React'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import { SystemParamsProvider } from 'contexts'
import store from 'state'

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <ModalProvider>
            <HelmetProvider>
              <ThemeContextProvider>
                <RefreshContextProvider>
                  <SystemParamsProvider>
                    {/*  <ComputedPowerTokenProvider>
                     */}{' '}
                    {children}
                    {/*   </ComputedPowerTokenProvider>
                     */}{' '}
                  </SystemParamsProvider>
                </RefreshContextProvider>
              </ThemeContextProvider>
            </HelmetProvider>
          </ModalProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
