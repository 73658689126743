// calculatorSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';
import { TokenUtils, toSmallUnits } from 'utils/transformHelper';

interface CalculatorState {
  collateralValue: BigNumber;
  prepaidInterest: BigNumber;
  obligation: BigNumber;
  epochDebt: BigNumber;
  estimatedDailyDebt: BigNumber;
  debt: BigNumber;
  estimatedObligationToday: BigNumber;
  collateralRatio: BigNumber;
  prepaidInterestCoverage: BigNumber;
  shortBalanceValue: BigNumber
  tempState: {
    collateralRatio: BigNumber;
    prepaidInterestCoverage: BigNumber;
  };
}

const initialState: CalculatorState = {
  collateralValue: ethers.constants.Zero, // 1 ETH
  prepaidInterest:  ethers.constants.Zero, // 0.5 ETH
  obligation:  ethers.constants.Zero, // 0.2 ETH
  epochDebt:  ethers.constants.Zero, // 0.3 ETH
  estimatedDailyDebt:  ethers.constants.Zero, // 0.1 ETH
  debt: ethers.constants.Zero, // 2 ETH
  estimatedObligationToday:  ethers.constants.Zero, // 0.001 ETH
  collateralRatio: ethers.BigNumber.from(0),
  prepaidInterestCoverage: ethers.BigNumber.from(0),
  shortBalanceValue: ethers.BigNumber.from(0),
  tempState: {
    collateralRatio: ethers.BigNumber.from(0),
    prepaidInterestCoverage: ethers.BigNumber.from(0),
  },
};

const calculateCollateralRatio = (
  collateralValue: BigNumber,
  prepaidInterest: BigNumber,
  shortBalanceValue: BigNumber
): BigNumber => {
  const numerator = collateralValue.add(prepaidInterest)
  console.log('numerator.div(shortBalanceValue)', numerator.div(shortBalanceValue))
  return toSmallUnits(TokenUtils.formatTokenAmount(numerator.toNumber() / shortBalanceValue.toNumber() * 100, 2), 2) // multiply by 100 to get percentage
}

const calculatePrepaidInterestCoverage = (
  prepaidInterest: BigNumber,
  estimatedDailyDebt: BigNumber
): BigNumber => {
  return prepaidInterest.div(estimatedDailyDebt);
}

const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    initCalculatorSlice(state, action: PayloadAction<CalculatorState>) {
      console.log('1', 1)
      state.collateralValue = action.payload.collateralValue;
      state.prepaidInterest = action.payload.prepaidInterest;
      state.obligation = action.payload.obligation;
      state.epochDebt = action.payload.epochDebt;
      state.estimatedDailyDebt = action.payload.estimatedDailyDebt;
      state.debt = action.payload.debt;
      state.estimatedObligationToday = action.payload.estimatedObligationToday;
      state.shortBalanceValue = action.payload.shortBalanceValue
      state.collateralRatio = calculateCollateralRatio(
        state.collateralValue,
        state.prepaidInterest,
        state.shortBalanceValue
      );
      state.prepaidInterestCoverage = calculatePrepaidInterestCoverage(
        state.prepaidInterest,
        state.estimatedDailyDebt
      );
      state.tempState.collateralRatio = state.collateralRatio
      state.tempState.prepaidInterestCoverage = state.prepaidInterestCoverage
    },
    setTempCollateral: (state, action: PayloadAction<BigNumber>) => {
      state.tempState.collateralRatio = action.payload;
    },
    setTempPrepaidInterest: (state, action: PayloadAction<BigNumber>) => {
      state.tempState.prepaidInterestCoverage = action.payload;
    },
    addCollateral: (state) => {
      state.collateralRatio = calculateCollateralRatio(
        state.collateralValue,
        state.prepaidInterest,
        state.shortBalanceValue,
      );
      state.tempState.collateralRatio = ethers.BigNumber.from(0);
    },
    addPrepaidInterest: (state) => {
      state.prepaidInterest = state.prepaidInterest.add(state.tempState.prepaidInterestCoverage);
      state.collateralRatio = calculateCollateralRatio(
        state.collateralValue,
        state.prepaidInterest,
        state.shortBalanceValue,
        
      );
      state.prepaidInterestCoverage = calculatePrepaidInterestCoverage(
        state.prepaidInterest,
        state.estimatedDailyDebt
      );
      state.tempState.prepaidInterestCoverage = ethers.BigNumber.from(0);
    },
    withdrawAsset: (state) => {
      state.collateralValue = state.collateralValue.sub(state.tempState.collateralRatio);
      state.collateralRatio = calculateCollateralRatio(
        state.collateralValue,
        state.prepaidInterest,
        state.shortBalanceValue,
      );
      state.tempState.collateralRatio = ethers.BigNumber.from(0);
    },
    moveCollateralToPrepaidInterest: (state) => {
      const amount = state.tempState.collateralRatio;
      state.collateralValue = state.collateralValue.sub(amount);
      state.prepaidInterest = state.prepaidInterest.add(amount);
      state.prepaidInterestCoverage = calculatePrepaidInterestCoverage(
        state.prepaidInterest,
        state.estimatedDailyDebt,
      );
      state.tempState.collateralRatio = ethers.BigNumber.from(0);
    },
    movePrepaidInterestToWallet: (state) => {
      state.prepaidInterest = state.prepaidInterest.sub(state.tempState.prepaidInterestCoverage);
      state.collateralRatio = calculateCollateralRatio(
        state.collateralValue,
        state.prepaidInterest,
        state.shortBalanceValue,
      );
      state.prepaidInterestCoverage = calculatePrepaidInterestCoverage(
        state.prepaidInterest,
        state.estimatedDailyDebt
      );
      state.tempState.prepaidInterestCoverage = ethers.BigNumber.from(0);
    },
    movePrepaidInterestToCollateral: (state) => {
      const amount = state.tempState.prepaidInterestCoverage;
      state.prepaidInterest = state.prepaidInterest.sub(amount);
      state.collateralValue = state.collateralValue.add(amount);
      state.prepaidInterestCoverage = calculatePrepaidInterestCoverage(
        state.prepaidInterest,
        state.estimatedDailyDebt,
      );
      state.tempState.prepaidInterestCoverage = ethers.BigNumber.from(0);
    },
    resetTempState: (state) => {
      state.tempState.collateralRatio = ethers.BigNumber.from(0);
      state.tempState.prepaidInterestCoverage = ethers.BigNumber.from(0);
    },
  },
});

export const {
  setTempCollateral,
  setTempPrepaidInterest,
  addCollateral,
  addPrepaidInterest,
  withdrawAsset,
  moveCollateralToPrepaidInterest,
  movePrepaidInterestToWallet,
  movePrepaidInterestToCollateral,
  resetTempState,
  initCalculatorSlice
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
