import { useWeb3React } from 'web3-react-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BlockUpdater from 'config/abi/BlockUpdater.json'
import moment from 'moment'
import { BigNumber, ethers } from 'ethers'
import { SupportedChainId } from 'config/constants/chains'
import { blastSepoliaMintTokens, mintTokenChainConfigs, MintTokenKey } from 'config/constants/mintToken'
import { valueToKeyMap } from 'config/constants/tokenConfig'
import { addEpochData, Epoch, setEpochCount, setEpochCurrent } from 'state/epochSlice'
import { getContract, getSigner, isSupportedChainId } from 'utils/web3React'
import { TokenUtils, toLargeUnits, toSmallUnits } from 'utils/transformHelper'
import Multicall from 'utils/multicall'
import contracts from 'config/constants/contracts'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'state'
import _ from 'lodash'
import { usePositionInfo } from './news/usePoistion'

const BlockUpdaterInfterface = new ethers.utils.Interface(BlockUpdater)

export default function useEpoch() {
  const { active, chainId, library, account } = useWeb3React()
  const [currentEpoch, setCurrentEpoch] = useState<Epoch>({
    epoch: 0,
    start: 'YYYY-MM-DD HH:ss',
    factor: '0',
    acc: BigNumber.from(0),
  })
  const [prevEpoch, setPrevEpoch] = useState<Epoch>({
    epoch: 0,
    start: 'YYYY-MM-DD HH:ss',
    factor: '0',
    acc: BigNumber.from(0),
  })
  const [epochMap, setEpochMap] = useState<Map<number, Epoch[]>>(new Map())
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const { currentMintToken } = usePositionInfo()
  const currentTokenInfo = useMemo(() => {
    if (chainId) {
      return mintTokenChainConfigs[chainId as SupportedChainId].tokens[valueToKeyMap[currentMintToken] as MintTokenKey]
    }
    return blastSepoliaMintTokens[valueToKeyMap[currentMintToken] as MintTokenKey]
  }, [chainId, currentMintToken])
  const computeFactor = useCallback(
    (factor: BigNumber) => {
      return Number(factor) > 0
        ? `${TokenUtils.formatTokenAmount(
            Number(
              toLargeUnits(
                factor
                  .mul(toSmallUnits('1', currentTokenInfo.decimals))
                  .mul(60 * 60 * 24)
                  .div(toSmallUnits('1', currentTokenInfo.settlementCurrency.decimals)),
                18,
              ),
            ),
            currentTokenInfo.settlementCurrency.decimals,
          )} ${currentTokenInfo.settlementCurrency.symbol}`
        : 0
    },
    [currentTokenInfo],
  )
  const loadData = useCallback(
    async (page = 1) => {
      if (active && chainId && library && account && isSupportedChainId(chainId)) {
        if (page < 1 || page > pages) {
          return
        }
        if (epochMap.has(page)) {
          setCurrentPage(page)
          return
        }
        setLoading(true)
        const signer = getSigner(library, account)
        // const list = []
        const updater = getContract(signer, BlockUpdater, currentTokenInfo.blockUpdater)
        if (updater) {
          try {
            const start = (page - 1) * 10
            const end = Math.min(start + 10, currentEpoch.epoch)
            const fetchList = []
            const max = currentEpoch.epoch
            for (let i = start; i < end; i++) {
              fetchList.push(updater.epochs(max - i))
            }
            const epochList = await Promise.all(fetchList)
            setCurrentPage(page)
            setEpochMap((prev) => {
              const map = new Map(prev)
              map.set(
                page,
                epochList.map((it, index) => {
                  const date = Number(it[0].mul(1000))
                  const factor = computeFactor(it[1])
                  return {
                    epoch: max - ((page - 1) * 10 + index),
                    start: date ? moment(date).format('YYYY-MM-DD HH:ss') : date,
                    factor,
                    acc: it[2],
                  }
                }),
              )
              return map
            })
            setLoading(false)
          } catch (e) {
            console.error('error', (e as Error).message)
            setLoading(false)
          }
        }
      }
    },
    [account, active, chainId, computeFactor, currentEpoch.epoch, currentTokenInfo, epochMap, library, pages],
  )

  const getCurrentEpoch = useCallback(async () => {
    if (active && chainId && library && account && isSupportedChainId(chainId)) {
      const signer = getSigner(library, account)
      const updater = getContract(signer, BlockUpdater, currentTokenInfo.blockUpdater)
      if (updater) {
        try {
          const _currentEpoch = await updater.getCurrentEpoch()
          const [currentEpochInfo, prevEpochInfo] = await Promise.all([
            updater.epochs(_currentEpoch),
            updater.epochs(_currentEpoch - 1),
          ])
          const currentStart = Number(toLargeUnits(currentEpochInfo.StartTime, 0)) * 1000
          const prevStart = Number(toLargeUnits(prevEpochInfo.StartTime, 0)) * 1000
          setCurrentEpoch({
            epoch: _currentEpoch / 1,
            start: currentStart ? moment(currentStart).format('YYYY-MM-DD HH:ss') : currentStart,
            factor: computeFactor(currentEpochInfo[1]),
            acc: currentEpochInfo[2],
          })
          setPrevEpoch({
            epoch: (_currentEpoch - 1) / 1,
            start: prevStart ? moment(prevStart).format('YYYY-MM-DD HH:ss') : prevStart,
            factor: computeFactor(prevEpochInfo[1]),
            acc: prevEpochInfo[2],
          })
          setTotal(_currentEpoch / 1)
          setPages(Math.ceil(_currentEpoch / 1 / 10))
        } catch (e) {
          console.error('error', (e as Error).message)
        }
      }
    }
  }, [account, active, chainId, computeFactor, currentTokenInfo.blockUpdater, library])

  useEffect(() => {
    getCurrentEpoch()
  }, [getCurrentEpoch])

  const epochList = useMemo(() => {
    return epochMap.get(currentPage)
  }, [epochMap, currentPage])

  const onClear = useCallback(() => {
    setEpochMap(new Map())
    setCurrentEpoch({
      epoch: 0,
      start: 'YYYY-MM-DD HH:ss',
      factor: '0',
      acc: BigNumber.from(0),
    })
    setPrevEpoch({
      epoch: 0,
      start: 'YYYY-MM-DD HH:ss',
      factor: '0',
      acc: BigNumber.from(0),
    })
    setTotal(0)
    setPages(0)
    setCurrentPage(1)
    setLoading(false)
  }, [])

  return {
    currentEpoch,
    prevEpoch,
    epochList,
    epochMap,
    loadData,
    getCurrentEpoch,
    loading,
    currentPage,
    pagination: {
      total,
      pages,
      pageNo: currentPage,
    },
    onClear,
  }
}

export function useEpochInfo() {
  const { currentMintToken, currentPosition } = usePositionInfo()
  const { chainId } = useWeb3React()
  const dispatch = useDispatch()
  const multicall = useMemo(
    () => new Multicall(chainId, contracts.uniswapV2Multicall.address, contracts.uniswapV2Multicall.abi),
    [chainId],
  )
  multicall.addAbi(currentPosition.mintTokenInfo.blockUpdater, BlockUpdater)

  const { epochList, epochCurent } = useAppSelector((state) => state.epochSlice)
  // const
  const currentTokenInfo = useMemo(() => {
    if (chainId) {
      return mintTokenChainConfigs[chainId as SupportedChainId].tokens[valueToKeyMap[currentMintToken] as MintTokenKey]
    }
    return blastSepoliaMintTokens[valueToKeyMap[currentMintToken] as MintTokenKey]
  }, [chainId, currentMintToken])

  const computeFactor = useCallback(
    (factor: BigNumber) => {
      return Number(factor) > 0
        ? `${TokenUtils.formatTokenAmount(
            Number(
              toLargeUnits(
                factor
                  .mul(toSmallUnits('1', currentTokenInfo.decimals))
                  .mul(60 * 60 * 24)
                  .div(toSmallUnits('1', currentTokenInfo.settlementCurrency.decimals)),
                18,
              ),
            ),
            currentTokenInfo.settlementCurrency.decimals,
          )} ${currentTokenInfo.settlementCurrency.symbol}`
        : 0
    },
    [currentTokenInfo],
  )

  const getCurrentEpoch = useCallback(async () => {
    if (currentMintToken) {
      const updaterContract = new ethers.Contract(
        currentPosition.mintTokenInfo.blockUpdater,
        BlockUpdater,
        multicall.provider,
      )
      if (updaterContract) {
        try {
          const _currentEpoch = await updaterContract.getCurrentEpoch()
          dispatch(
            setEpochCurrent({
              token: valueToKeyMap[currentMintToken] as MintTokenKey,
              current: _currentEpoch.toNumber(),
            }),
          )
          dispatch(
            setEpochCount({
              token: valueToKeyMap[currentMintToken] as MintTokenKey,
              count: _currentEpoch.toNumber(),
            }),
          )
          const currentEpochInfo = await updaterContract.epochs(_currentEpoch)

          const currentStart = Number(toLargeUnits(currentEpochInfo.StartTime, 0)) * 1000
          dispatch(
            addEpochData({
              token: valueToKeyMap[currentMintToken] as MintTokenKey,
              list: [
                {
                  epoch: _currentEpoch / 1,
                  start: currentStart ? moment(currentStart).format('YYYY-MM-DD HH:ss') : currentStart,
                  factor: computeFactor(currentEpochInfo[1]),
                  acc: currentEpochInfo[2],
                },
              ],
            }),
          )
        } catch (e) {
          console.error('error', (e as Error).message)
        }
      }
    }
  }, [computeFactor, currentMintToken, currentPosition.mintTokenInfo.blockUpdater, dispatch, multicall.provider])

  const getLocalEpoch = useCallback(
    (page = 1) => {
      const list = epochList[valueToKeyMap[currentMintToken] as MintTokenKey] || []
      if (list.length === 0) {
        return []
      }
      const sortedList = _.orderBy(list, ['epoch'], ['desc'])
      const pageSize = 10
      const startIndex = (page - 1) * pageSize
      const paginatedList = _.slice(sortedList, startIndex, startIndex + pageSize)

      return paginatedList
    },
    [currentMintToken, epochList],
  )

  const loadData = useCallback(
    async (pageNo = 1) => {
      const localList = getLocalEpoch(pageNo)
      const pageTotal = epochCurent[valueToKeyMap[currentMintToken] as MintTokenKey] + 1 || 0
      const fetchList = []
      const start = (pageNo - 1) * 10
      const end = Math.min(start + 10, pageTotal)

      // if (start > pageTotal) {
      //   return []
      // }
      const fetchSize = Math.min(10, pageTotal - start)
      if (fetchSize === localList.length) {
        return localList
      }
      const indexs = []
      for (let i = start; i < end; i++) {
        indexs.push(pageTotal - i - 1)
        fetchList.push({
          target: currentPosition.mintTokenInfo.blockUpdater,
          callData: BlockUpdaterInfterface.encodeFunctionData('epochs', [pageTotal - i -1]),
          functionSignature: 'epochs',
        })
      }

      const { returnData } = await multicall.aggregate(fetchList)
      const remoteList = returnData.epochs.map((it, index) => {
        const date = Number(toLargeUnits(it[0], 0)) * 1000
        const factor = computeFactor(it[1])
        return {
          epoch: indexs[index],
          start: date ? moment(date).format('YYYY-MM-DD HH:ss') : date,
          factor,
          acc: it[2],
        }
      })
      dispatch(
        addEpochData({
          token: valueToKeyMap[currentMintToken] as MintTokenKey,
          list: remoteList,
        }),
      )
      return remoteList
    },
    [
      computeFactor,
      currentMintToken,
      currentPosition.mintTokenInfo.blockUpdater,
      dispatch,
      epochCurent,
      getLocalEpoch,
      multicall,
    ],
  )

  useEffect(() => {
    if (chainId && isSupportedChainId(chainId)) {
      getCurrentEpoch()
    }
  }, [chainId, getCurrentEpoch]);

  return {
    loadData,
    getLocalEpoch,
    epochCurent: epochCurent[valueToKeyMap[currentMintToken]] + 1 || 0

  }
}
