import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router'
import { useWeb3React } from 'web3-react-core'
import NetworkSelector from 'components/NetworkSelector'
import Logo from './icons/Logo'
import Slider from './components/Slider'
import Flex from '../uikit/components/Box/Flex'
import UserBlock from './components/UserBlock'
import { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'
import EpochStatus from './components/EpochStatus'
import { isSupportedChainId } from '../../utils/web3React'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6) linear-gradient(116.82deg, rgb(234, 254, 255) 0%, rgb(186, 224, 255) 100%); ;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: relative;
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 37px;
  padding-right: 100px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  height: 100%;
  width: calc(100% - 283px);
  padding-left: 70px;
  padding-top: 39px;
  box-sizing: border-box;
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);

  ${({ theme }) => theme.mediaQueries.nav} {
    //margin-left: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    //max-width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`};
  }
`

const SliderWrapper = styled('div')`
  overflow: scroll;
`
const SliderContent = styled('div')`
  width: 283px;
  position: relative;
  height: calc(100% - 84px);
  min-height: 850px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Menu = ({ account, login, logout, children, location }) => {
  const [showMenu] = useState(true)
  const { chainId, active } = useWeb3React()

  const childerDom = useMemo(() => {
    const childers = isSupportedChainId(chainId) || location.pathname === '/mypage' ? children : 'Unsupported Network'
    return location.pathname !== '/mypage' && !active ? null : childers
  }, [active, chainId, children, location.pathname])

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu}>
        <Link to="/">
          <Logo style={{ width: 220, height: 37.01, marginTop: 10 }} />
        </Link>

        <Flex style={{ justifyContent: 'space-between', flexDirection: !account ? 'row-reverse' : 'row' }}>
          <NetworkSelector />
          <UserBlock account={account} login={login} logout={logout} />
        </Flex>
      </StyledNav>
      <BodyWrapper>
        {/* slider position */}
        <SliderWrapper>
          <SliderContent>
            <Slider />
            <EpochStatus />
          </SliderContent>
        </SliderWrapper>
        <Inner isPushed={false} showMenu={showMenu}>
          {childerDom}
        </Inner>
      </BodyWrapper>
    </Wrapper>
  )
}

export default withRouter(Menu as any)
