import { useContext } from 'react'
import {RefreshContext} from "contexts/RefreshContext";

const useRefesh = () => {
  const systemParams = useContext(RefreshContext)
  if (systemParams === undefined) {
    throw new Error('systemParams context undefined')
  }

  return systemParams
}

export default useRefesh
