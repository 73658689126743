import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MappingFunk',
  description:'',
  image: '',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Home',
  },
  '/competition': {
    title: 'Trading Battle',
  },
  '/prediction': {
    title: 'Prediction',
  },
  '/farms': {
    title: 'Farms',
  },
  '/pools': {
    title: 'Pools',
  },
  '/lottery': {
    title: 'Lottery',
  },
  '/collectibles': {
    title: 'Collectibles',
  },
  '/ifo': {
    title: 'Initial Farm Offering',
  },
  '/teams': {
    title: 'Leaderboard',
  },
  '/profile/tasks': {
    title: 'Task Center',
  },
  '/profile': {
    title: 'Your Profile',
  },
}
