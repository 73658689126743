import { useContext } from 'react'
import {SystemParamsContext} from "contexts/SystemParamsContext";

const useSystemParams = () => {
  const systemParams = useContext(SystemParamsContext)
  if (systemParams === undefined) {
    throw new Error('systemParams context undefined')
  }

  return systemParams
}

export default useSystemParams
