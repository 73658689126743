import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from 'web3-react-core'
import { BG_COMMON_COLOR } from 'components/Menu/config'
import Button from '../../uikit/components/Button/Button'
import { Login, useWalletModal } from '../../uikit/components/WalletModal'
import { Wallet } from '../icons'

interface Props {
  account?: string
  login: Login
  logout: () => void
  balance?: number
}
const WalletButton = styled(Button)`
   {
    font-family: PingFangSC-Medium, sans-serif;
    color: #2aa1ff !important;
    background: ${BG_COMMON_COLOR};
    border: 1px solid rgba(207, 216, 226, 0.7);
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    font-weight: 500;
    font-size: 12px;
    height: 28px;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    transition: 3s;
    svg {
      width: 17px;
      height: 13px;
      margin-right: 9px;
    }
    span {
      margin-left: 5px;
      line-height: 22px;
      transition: 3s;
    }
  }
`

const ConnectButton = styled(Button)`
  background: linear-gradient(90deg, #61c2f7 0%, #7ae9f0 100%), #5e5adb;
  box-shadow: none;
  height: 28px;
  font-size: 12px;
`

const UserBlock: React.FC<Props> = ({ login, logout }) => {
  const { account } = useWeb3React()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  // const balance = useCurrentBalance();
  const accountEllipsis = account && `${account.slice(0, 6)}...${account.toString().slice(-6)}`
  const [moveStatus, setMoveStatus] = useState(false)

  const mouseMove = useCallback(() => {
    setMoveStatus((state) => !state)
  }, [])
  return (
    <div>
      {account ? (
        <WalletButton
          scale="sm"
          variant="tertiary"
          onClick={() => {
            onPresentAccountModal()
          }}
          onMouseEnter={mouseMove}
          onMouseLeave={mouseMove}
        >
          <Wallet /> <span>{moveStatus ? account : accountEllipsis}</span>
        </WalletButton>
      ) : (
        <ConnectButton
          scale="sm"
          onClick={() => {
            onPresentConnectModal()
          }}
        >
          Connect Wallet
        </ConnectButton>
      )}
    </div>
  )
}

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account)
