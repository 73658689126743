import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useWeb3React } from 'web3-react-core'
import useRefesh from 'hooks/useRefresh'
import { useGetTokenBalance } from 'hooks/useManager'

const HashPowerTokenContext = React.createContext<{
  hashPowerTokenList: any[]
  refresh: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
  setHashPowerTokenList: Dispatch<SetStateAction<any[]>>
  computed: (_mintAmount: any, cptInfo: any, callback: (ratio: any, gDays: any, amount: any) => any) => void
  walletTokenBalance: any
  mintComputed: (
    _radio: number,
    cptInfo: any,
    callback: (currentMintAmount: any, gDays: any, amount: any) => any,
  ) => any,
  onUpdateWalletBalance: () => void
}>(null)

function HashPowerTokenProvider({ children }) {
  const { active, chainId, account, library } = useWeb3React()
  const [hashPowerTokenList, setHashPowerTokenList] = useState([])
  const [walletTokenBalance, setWalletBalance] = useState({})
  const [refresh, setRefresh] = useState(false)
  const { getBalance } = useGetTokenBalance(library, active, chainId)
  const { fast } = useRefesh()
  
  // knowing mint
  const computed = useCallback(() => null, [])

  // knowing radio
  const mintComputed = useCallback(() => null, [])
  
  const onUpdateWalletBalance = useCallback(() => {
    getBalance(account).then((balances) => {
      setWalletBalance((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(balances)) {
          return prev
        }
        return balances
      })
    }).catch((e) => {
      console.error('get wallet balance error', e)
    })
  }, [account, getBalance])

  useEffect(() => {
    onUpdateWalletBalance()
  }, [onUpdateWalletBalance, fast])

  return (
    <HashPowerTokenContext.Provider
      value={{
        hashPowerTokenList,
        refresh,
        setRefresh,
        setHashPowerTokenList,
        computed,
        walletTokenBalance,
        mintComputed,
        onUpdateWalletBalance,
      }}
    >
      {children}
    </HashPowerTokenContext.Provider>
  )
}

export { HashPowerTokenContext, HashPowerTokenProvider }
