import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" fill="none" {...props}>
      <circle cx="9" cy="9" r="8.4" stroke="#6ED6F3" strokeWidth="1.2"/>
      <path
        d="M8.09671 10.2673H6.32963C6.09892 10.2673 5.93978 10.0364 6.02188 9.82095L7.77776 5.21205C7.80152 5.1497 7.84365 5.09603 7.89859 5.05816C7.95353 5.02029 8.01868 5.00001 8.08541 5H11.0484C11.2822 5 11.4415 5.23685 11.3533 5.4534L10.3309 7.96288H12.2556C12.5387 7.96288 12.6897 8.29654 12.503 8.50925L7.78413 13.8862C7.55474 14.1477 7.12943 13.9211 7.21844 13.5849L8.09671 10.2673ZM8.31228 5.65854L6.80729 9.6089H8.52433C8.57474 9.60891 8.62448 9.62049 8.66971 9.64275C8.71494 9.66501 8.75445 9.69737 8.7852 9.73731C8.81595 9.77726 8.83712 9.82373 8.84708 9.87315C8.85703 9.92257 8.85551 9.97362 8.84262 10.0224L8.2136 12.3988L11.5287 8.62131H9.84129C9.60751 8.62131 9.44815 8.38446 9.53639 8.16791L10.5588 5.65843H8.31228V5.65854Z"
        fill="#6ED6F3" stroke="#6ED6F3" strokeWidth="0.4"/>
    </Svg>
  )
}

export default Icon
