import React from 'react'
import Liquidation from "../../../../../assets/image/Liquidation.png"

const Icon = (props) => {
  return (
    <img src={Liquidation} {...props} alt="" />
  )
}

export default Icon
