import * as React from 'react'
import { Input } from '@mui/material'
import SelectUnstyled, { SelectUnstyledProps, selectUnstyledClasses } from '@mui/base/SelectUnstyled'
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled'
import PopperUnstyled from '@mui/base/PopperUnstyled'
import { styled } from '@mui/system'
import down from 'assets/image/down.png'
import { useState } from 'react'
import { BG_COMMON_COLOR } from 'components/Menu/config'
import { commonTokens, valueToKeyMap } from 'config/constants/tokenConfig'
import { tokensSymbol } from '../../config/constants/types'

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
}

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
}

const StyledButton = styled('button')(
  ({ theme }) => `
  position: relative;
  font-weight: 550;
  box-sizing: border-box;
  width: 214px;
  background: ${BG_COMMON_COLOR};
  border: 1px solid rgba(207, 216, 226, 0.5);
  border-radius: 0.75em;
  margin: 0.5em 0;
  padding:8px 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  span{
    font-family: PingFangSC-Semibold, sans-serif;
    font-size: 14px;
  }
  &:hover {
    background: unset;
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      transform:  translateY(-50%) rotate(180deg) ; 
    }
  }
  &.${selectUnstyledClasses.disabled} {
    background: transparent !important;
    &.select_custom{
       background: ${BG_COMMON_COLOR};
    }
    &::after {
      display:none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    display: block;
    width: 7px;
    height: 5px;
    transition:transform 0.3s;
    transform: translateY(-50%);
    background: url(${down});
  }
  `,
)

const StyledListbox = styled('ul')(
  ({ theme }) => `
  width: 214px;
  box-sizing: border-box;
  padding: 9px 0;
  margin: 10px 0;
  font-size: 14px;
  font-family: PingFangSC-Semibold, sans-serif;
  font-weight: 550;
  transition: .3s;
  background: rgba(255, 255, 255, 1);
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `,
)

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  list-style: none;
  padding: 5px 10px;

  box-sizing: border-box;
  cursor: default;
  span {
    font-size: 14px;
    font-family: PingFangSC-Semibold, sans-serif;
  }
  &:last-of-type {
    border-bottom: none;
  }
  color: #000000;
  font-weight: bold;
  transition: .3s;
  &.${optionUnstyledClasses.selected} {
    background: rgba(255, 255, 255, 0.41);
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
   background: rgba(255, 255, 255, 0.41);
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background:  rgba(110, 214, 243, 0.23);
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
)

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`
const Group = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 260px;
  padding: 12px;
  border: 1px solid #cfd8e280;
  background-color: ${BG_COMMON_COLOR};
  border-radius: 11px;
  box-sizing: border-box;

  .MuiSelectUnstyled-root {
    margin: 0;
    font-size: 14px;
    font-family: 'PingFang SC';
    line-height: 24px;
    height: 24px;
    padding: 0;
    //margin-bottom: ;
    background-color: transparent;
    border: none;
    width: 50%;

    &:before {
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      height: 100%;
      width: 1px;
      background-color: rgba(207, 216, 226, 0.5);
    }

    + div.MuiSelectUnstyled-popper {
      padding: 10px 0;
      margin-left: -13px !important;
    }
  }

  div.MuiInput-root {
    padding: 0;
    width: 50%;
    color: #000;
    font-weight: bold;

    input::-webkit-inner-spin-button {
      display: none;
    }

    input {
      padding: 0;
      text-align: right;
    }
  }
`

const CustomSelect = React.forwardRef(function CustomSelect<TValue>(
  props: SelectUnstyledProps<TValue>,
  ref: React.ForwardedRef<HTMLUListElement>,
) {
  const components: SelectUnstyledProps<TValue>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  }

  return <SelectUnstyled {...props} ref={ref} components={components} />
}) as <TValue>(props: SelectUnstyledProps<TValue> & React.RefAttributes<HTMLUListElement>) => JSX.Element

export default function UnstyledSelectSimple(props) {
  const {
    options,
    type,
    isGroup,
    groupStyle,
    selectDisabled,
    allDisabled,
    inputDisabled,
    selectValue,
    onChange,
    actionValue,
    selectInput,
    onBlur,
  } = props
  const [opt] = useState(options)
  const OptionItem = React.useCallback((it: any) => {
    if (it.icon) {
      return (
        <it.icon
          style={{
            marginRight: 12,
          }}
        />
      )
    }
    if (it.token === tokensSymbol.FBTC20 || it.token === tokensSymbol.FBTC25) {
      return <img src="/svg/fbtc20.svg" style={{ marginRight: 12, width: 20, height: 20 }} alt="" />
    }

    return (
      <span
        style={{
          display: 'inline-block',
          marginRight: 12,
          width: 22,
          height: 22,
          borderRadius: '50%',
          background: '#000',
        }}
      />
    )
  }, [])

  if (type === 'network') {
    return (
      <CustomSelect disabled={selectDisabled || allDisabled} value={selectValue} onChange={onChange}>
        {opt.map((it) => (
          <StyledOption value={it.chainId} key={it.chainId}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {it.icon ? (
                it.icon
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: 12,
                    width: 22,
                    height: 22,
                    borderRadius: '50%',
                    background: '#000',
                  }}
                />
              )}
              <span style={{ lineHeight: 1 }}>{it.networkName}</span>
            </span>
          </StyledOption>
        ))}
      </CustomSelect>
    )
  }

  return (
    <>
      {!isGroup ? (
        <CustomSelect
          className="select_custom"
          disabled={selectDisabled || allDisabled}
          value={selectValue}
          onChange={onChange}
        >
          {opt
            ? options.map((it) => (
                <StyledOption value={it.token} key={it.token}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {OptionItem(it)}
                    <span style={{ lineHeight: 1 }}>{it.token}</span>
                  </span>
                </StyledOption>
              ))
            : null}
        </CustomSelect>
      ) : (
        <Group style={groupStyle}>
          <CustomSelect disabled={selectDisabled || allDisabled} value={selectValue} onChange={onChange}>
            {options.map((it) => (
              <StyledOption value={it.token} key={it.token}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {OptionItem(it)}
                  <span style={{ lineHeight: 1 }}>{it.token}</span>
                </span>
              </StyledOption>
            ))}
          </CustomSelect>
          <Input
            id="mediaVal"
            title=""
            onBlur={onBlur}
            disabled={allDisabled || inputDisabled}
            value={actionValue || ''}
            disableUnderline
            type="text"
            // onInput={(e) => {
            //   if (selectInput) {
            //     selectInput(e)
            //   }
            // }}
            onChange={(event) => {
              const inputValue = event.target.value
              console.log('inputValue', )
              let formattedValue = ''
              const cleanedValue = inputValue.replace(/[^\d.]/g, '')
              if (cleanedValue.includes('.')) {
                const parts = cleanedValue.split('.')
                parts[1] = parts[1]?.slice(0, commonTokens[valueToKeyMap[selectValue]].decimals)
                formattedValue = parts.join('.')
              } else {
                formattedValue = cleanedValue
              }
              if (Number(formattedValue) > 100000000) {
                formattedValue = '100000000'
              }

              if (formattedValue.includes('-') || formattedValue.includes('+')) {
                formattedValue = formattedValue.replace('-', '').replace('+', '')
              }
              
              if (selectInput) {
                selectInput({ target: { value: formattedValue } })
              }
            }}
          />
        </Group>
      )}
    </>
  )
}
