import { useEffect, useMemo, useCallback, useState } from 'react'
import { useWeb3React } from 'web3-react-core'
import { useAppDispatch, useAppSelector } from 'state'
import {
  fetchSinglePositionInfo,
  fetchTokenPrice,
  updateCollateralRatio,
  updateCurrentMintToken,
  updatePrepaidInterestCoverage,
} from 'state/MintToken/slice'
import PositionABI from 'config/abi/Position.json'
import { mintTokenChainConfigs, SupportedMintTokens, MintTokenKey } from 'config/constants/mintToken'
import useSystemParams from 'hooks/useSystemParams'
import { getProviderAndSigner, isSupportedChainId } from 'utils/web3React'
import { INFURA_NETWORK_URLS, SupportedChainId } from 'config/constants/chains'
import { Position } from 'state/MintToken/type'
import { commonTokens, tokenKey } from 'config/constants/tokenConfig'
import { ethers } from 'ethers'
import { TokenUtils, toLargeUnits, toSmallUnits } from 'utils/transformHelper'
import { ActionUserFunctionNames, Infinite, NeutralizeFee, ToastTitle } from 'config/constants/contants'
import { computedCR, computedPI, computedPIC, computedTC } from 'utils/computed'
import contracts from 'config/constants/contracts'
import useToast from 'hooks/useToast'
import BlockUpdaterABI from 'config/abi/BlockUpdater.json'
import useHashPowerToken from 'hooks/useHashPowerToken'

const { ActionUser } = contracts
export function usePositionInfo() {
  const { chainId } = useWeb3React()
  const { currentMintToken, positions, tokenPrice, allowedTokens } = useAppSelector((state) => state.MintToken)

  const positionAll = useMemo(() => {
    if (chainId && isSupportedChainId(chainId)) {
      const all = positions[chainId as SupportedChainId.BLAST_SEPPLIA]
      return all
    }
    return {} as {
      [K in MintTokenKey]: Position
    }
  }, [chainId, positions])
  const currentPosition = positionAll[SupportedMintTokens[currentMintToken]] as Position | null
  const currentPositionTokenInfo = useMemo(() => {
    if (
      currentPosition &&
      tokenPrice[currentPosition?.positionAddress] &&
      allowedTokens[currentPosition?.positionAddress]
    ) {
      const _tokenPrice = tokenPrice[currentPosition?.positionAddress]
      const { interestGuaranteed, obligation, epochDebt, estimatedObligationToday, collateralValue } =
        currentPosition?.positionStatus
      const prepaidInterest = computedPI({
        interestGuaranteed: Number(interestGuaranteed),
        obligation: Number(obligation),
        epochDebt: Number(epochDebt),
        estimatedObligationToday: Number(estimatedObligationToday),
      })
      const totalAssetsValue = toLargeUnits(
        computedTC({
          collateralValue: Number(collateralValue),
          prepaidInterest,
        }),
        currentPosition?.mintTokenInfo.settlementCurrency.decimals,
      ) as number
      return {
        tokenPrice: {
          ..._tokenPrice,
        },
        SettlementtoUSD: (_tokenPrice?.USDT && 1 / Number(_tokenPrice?.USDT?.price)) || 0,
        allowedTokens: {
          ...allowedTokens[currentPosition?.positionAddress],
        },
        totalAssetsValue,
        displayTotalAssetsValue: `${TokenUtils.formatFourDecimals(totalAssetsValue)} ${
          currentPosition?.mintTokenInfo.settlementCurrency.symbol
        }`,
      }
    }
    return undefined
  }, [allowedTokens, currentPosition, tokenPrice])

  const collateralIndex = useMemo(() => {
    if (currentPosition) {
      const { prepaidInterestCoverage, collateralRatio } = currentPosition
      const { interestGuaranteed, obligation, epochDebt, estimatedObligationToday } = currentPosition?.positionStatus
      const prepaidInterest = computedPI({
        interestGuaranteed: Number(interestGuaranteed),
        obligation: Number(obligation),
        epochDebt: Number(epochDebt),
        estimatedObligationToday: Number(estimatedObligationToday),
      })
      const totalAssetsValue = computedTC({
        collateralValue: Number(currentPosition.positionStatus.collateralValue),
        prepaidInterest,
      })
      return {
        prepaidInterestCoverage,
        collateralRatio,
        displayCoverage:
          prepaidInterestCoverage === Number.POSITIVE_INFINITY ? '+∞' : `${Math.floor(prepaidInterestCoverage)} days`,
        displayRatio:
          collateralRatio === Number.POSITIVE_INFINITY
            ? '+∞'
            : (collateralRatio && `${TokenUtils.formatTokenAmount(collateralRatio, 2)}%`) || `${collateralRatio}`,
        prepaidInterest: Number(
          TokenUtils.formatTokenAmount(
            toLargeUnits(prepaidInterest, currentPosition?.mintTokenInfo.settlementCurrency.decimals, true) as number,
            currentPosition?.mintTokenInfo.settlementCurrency.decimals,
          ),
        ),
        totalAssetsValues: toLargeUnits(
          totalAssetsValue,
          currentPosition?.mintTokenInfo.settlementCurrency.decimals,
        ) as number,
        estimatedDailyDebt: toLargeUnits(
          Number(currentPosition.positionStatus.estimatedDailyDebt),
          currentPosition.mintTokenInfo.settlementCurrency.decimals,
        ) as number,
        oustrandingDebt: toLargeUnits(currentPosition.balance, currentPosition.mintTokenInfo.decimals) as number,
      }
    }
    return {
      prepaidInterestCoverage: 0,
      collateralRatio: 0,
      displayCoverage: '0 days',
      displayRatio: '0',
      prepaidInterest: 0,
    }
  }, [currentPosition])

  return {
    currentMintToken,
    positionAll,
    currentPosition,
    tokenPrice,
    currentPositionTokenInfo,
    collateralIndex,
    allowedTokens,
  }
}

export function usePositionInit() {
  const { account, chainId } = useWeb3React()
  const { accountContract: accountRegister, signer, helperContract } = useSystemParams()
  const dispatch = useAppDispatch()

  const getPosition = useCallback(async () => {
    if (!chainId || !accountRegister) return
    const { tokens } = mintTokenChainConfigs[chainId]
    for (const key in tokens) {
      if (Object.prototype.hasOwnProperty.call(tokens, key)) {
        await dispatch(
          fetchSinglePositionInfo({
            account,
            chainId,
            accountRegister,
            signer,
            helperContract,
            tokenKey: key as MintTokenKey,
          }),
        )
      }
    }
  }, [account, accountRegister, chainId, dispatch, helperContract, signer])

  useEffect(() => {
    getPosition().catch(console.error)
  }, [getPosition])

  useUpdateCollateralRatio()
  useUpdatePrepaidInterestCoverage()
  // usePositionTokenPrice()
  // useUpdateAllowedBalance();
}

export function usePositionTokenPrice() {
  const { matrixContract } = useSystemParams()
  const { chainId } = useWeb3React()
  const { positionAll } = usePositionInfo()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!matrixContract || !chainId || !positionAll) return
    const fetchPrices = async () => {
      const promises = Object.keys(positionAll).map(async (position) => {
        const element = positionAll[position]
        if (element.isEnable) {
          await dispatch(
            fetchTokenPrice({
              chainId,
              mintToken: position as MintTokenKey,
              matrixContract,
              position: element.positionAddress,
            }),
          )
        }
      })
      await Promise.all(promises)
    }
    fetchPrices().catch(console.error)
  }, [chainId, dispatch, matrixContract, positionAll])
}

export function useUpdateCollateralRatio() {
  const { chainId } = useWeb3React()
  const { positionAll } = usePositionInfo()
  const dispatch = useAppDispatch()

  const handleCollateralRatio = useCallback(() => {
    if (!positionAll) return
    Object.keys(positionAll).forEach((position) => {
      const { positionStatus } = positionAll[position as MintTokenKey]
      if (positionAll[position as MintTokenKey].collateralRatio) return
      const prepaidInterest = computedPI({
        interestGuaranteed: Number(positionStatus.interestGuaranteed),
        obligation: Number(positionStatus.interestGuaranteed),
        epochDebt: Number(positionStatus.epochDebt),
        estimatedObligationToday: Number(positionStatus.estimatedObligationToday),
      })
      const totalCollateralValue = computedTC({
        collateralValue: Number(positionStatus.collateralValue),
        prepaidInterest,
      })
      const collateralRatio = computedCR({
        totalCollateralValue,
        shortBalanceValue: Number(positionStatus.shortBalanceValue),
      })
      dispatch(
        updateCollateralRatio({
          chainId,
          mintTokenKey: position as MintTokenKey,
          collateralRatio,
        }),
      )
    })
  }, [chainId, dispatch, positionAll])

  useEffect(() => {
    handleCollateralRatio()
  }, [handleCollateralRatio])
}

export function useUpdatePrepaidInterestCoverage() {
  const { chainId } = useWeb3React()
  const { positionAll } = usePositionInfo()
  const dispatch = useAppDispatch()

  const handlePrepaidInterestCoverage = useCallback(() => {
    if (!positionAll) return
    Object.keys(positionAll).forEach((position) => {
      const { positionStatus } = positionAll[position as MintTokenKey]
      if (positionAll[position as MintTokenKey].prepaidInterestCoverage) return
      const prepaidInterest = computedPI({
        interestGuaranteed: Number(positionStatus.interestGuaranteed),
        obligation: Number(positionStatus.obligation),
        epochDebt: Number(positionStatus.epochDebt),
        estimatedObligationToday: Number(positionStatus.estimatedObligationToday),
      })
      const prepaidInterestCoverage = computedPIC({
        prepaidInterest,
        estimatedDailyDebt: Number(positionStatus.estimatedDailyDebt),
      })
      dispatch(
        updatePrepaidInterestCoverage({
          chainId,
          mintTokenKey: position as MintTokenKey,
          prepaidInterestCoverage,
        }),
      )
    })
  }, [chainId, dispatch, positionAll])

  useEffect(() => {
    handlePrepaidInterestCoverage()
  }, [handlePrepaidInterestCoverage])
}

export function useComputedIndexAction() {
  const { currentPosition, currentPositionTokenInfo, currentMintToken } = usePositionInfo()
  const [actionPrepaidInterestVal, setActionPrepaidInterest] = useState(0)
  const [actionCollateralVal, setActionCollateralVal] = useState<{
    symbol: tokenKey
    value: number
  }>({
    symbol: currentPosition.mintTokenInfo.settlementCurrency.symbol as tokenKey || commonTokens.WBTC.symbol as tokenKey,
    value: 0,
  })
  const [actionNeutralizeVal, setActionNeutralizeVal] = useState(0)

  const [actionTotalCollateralVal, setActionTotalCollateralVal] = useState(0)
  const onUpdatePrepaidInterest = useCallback((value: number) => {
    setActionPrepaidInterest(value)
  }, [])

  const onUpdateCollateralValue = useCallback((props: { symbol: tokenKey; value: number }) => {
    setActionCollateralVal(props)
  }, [])

  const onUpdateNeutralizeValue = useCallback(
    (value: number) => {
      const balance = Number(currentPosition.balance) / 10 ** currentPosition.mintTokenInfo.decimals
      if (balance + value < 0) {
        setActionNeutralizeVal(0)
      } else {
        setActionNeutralizeVal(value)
      }
      // const safeMaxValue = Math.abs(value) * 10 ** currentPosition.mintTokenInfo.decimals
      // if (safeMaxValue > Number.MAX_SAFE_INTEGER) {
      //   setActionNeutralizeVal(0)
      // }
    },
    [currentPosition.balance, currentPosition.mintTokenInfo.decimals],
  )
  const onUpdateTotalCollateralValue = useCallback((value: number) => {
    setActionTotalCollateralVal(value)
  }, [])

  const newPrepaidInterest = useMemo(() => {
    const { positionStatus, mintTokenInfo } = currentPosition
    const { interestGuaranteed, obligation, epochDebt, estimatedObligationToday } = positionStatus
    const pi = computedPI({
      interestGuaranteed: Number(interestGuaranteed),
      obligation: Number(obligation),
      epochDebt: Number(epochDebt),
      estimatedObligationToday: Number(estimatedObligationToday),
    })
    return pi + actionPrepaidInterestVal * 10 ** mintTokenInfo.settlementCurrency.decimals
  }, [actionPrepaidInterestVal, currentPosition])

  const newCollateralValue = useMemo(() => {
    if (currentPositionTokenInfo?.tokenPrice) {
      const { tokenPrice } = currentPositionTokenInfo
      if (!tokenPrice) {
        return Number(currentPosition.positionStatus.collateralValue)
      }
      if (!tokenPrice[actionCollateralVal.symbol]) {
        return Number(currentPosition.positionStatus.collateralValue)
      }
      const addTokenPrice = Number(tokenPrice[actionCollateralVal.symbol].price)
      return (
        Number(currentPosition.positionStatus.collateralValue) +
        addTokenPrice * actionCollateralVal.value +
        actionTotalCollateralVal * 10 ** currentPosition.mintTokenInfo.settlementCurrency.decimals
      )
    }
    return Number(currentPosition.positionStatus.collateralValue)
  }, [actionCollateralVal, actionTotalCollateralVal, currentPosition, currentPositionTokenInfo])

  const newOutStandingDebt = useMemo(() => {
    return (
      Number(currentPosition.balance) +
      Number(toSmallUnits(String(actionNeutralizeVal), currentPosition.mintTokenInfo.decimals))
    )
  }, [actionNeutralizeVal, currentPosition.balance, currentPosition.mintTokenInfo.decimals])
  const newTotalAssetsValue = useMemo(() => {
    return computedTC({
      collateralValue: newCollateralValue,
      prepaidInterest: newPrepaidInterest,
    })
  }, [newCollateralValue, newPrepaidInterest])

  const newEstimatedDailyDebt = useMemo(() => {
    const unitEstimatedDailyDebt = Number(currentPosition.lastEpochReward)
    return Math.floor((unitEstimatedDailyDebt * newOutStandingDebt) / 10 ** 18)
  }, [currentPosition.lastEpochReward, newOutStandingDebt])
  const newPrepaidInterestCoverage = useMemo(() => {
    return computedPIC({
      prepaidInterest: newPrepaidInterest,
      estimatedDailyDebt: newEstimatedDailyDebt,
    })
  }, [newEstimatedDailyDebt, newPrepaidInterest])

  const newNeutralizeFee = useMemo(() => {
    if (currentPositionTokenInfo?.tokenPrice) {
      const { tokenPrice } = currentPositionTokenInfo
      const { decimals } = currentPosition.mintTokenInfo.settlementCurrency
      const netralizeValue = Math.abs(actionNeutralizeVal) * Number(tokenPrice[currentMintToken].price) * NeutralizeFee
      return Number(TokenUtils.formatTokenAmount(netralizeValue / 10 ** decimals, decimals))
    }
    return 0
  }, [actionNeutralizeVal, currentMintToken, currentPositionTokenInfo, currentPosition])

  const newShortBalanceValue = useMemo(() => {
    const { positionStatus, balance, mintTokenInfo } = currentPosition
    if (
      Math.abs(actionNeutralizeVal) &&
      toSmallUnits(String(Math.abs(actionNeutralizeVal)), mintTokenInfo.decimals).eq(balance)
    ) {
      return 0
    }
    if (currentPositionTokenInfo?.tokenPrice) {
      const { tokenPrice } = currentPositionTokenInfo
      const actionVal = actionNeutralizeVal * Number(tokenPrice[currentMintToken].price)
      return Number(positionStatus.shortBalanceValue) + actionVal
    }
    return Number(positionStatus.shortBalanceValue)
  }, [actionNeutralizeVal, currentMintToken, currentPosition, currentPositionTokenInfo])

  const newCollateralRatio = useMemo(() => {
    return computedCR({
      totalCollateralValue: newTotalAssetsValue,
      shortBalanceValue: newShortBalanceValue,
    })
  }, [newShortBalanceValue, newTotalAssetsValue])

  const minCollateralValue = useMemo(() => {
    const minCollateralRatio = 1.2
    const { positionStatus } = currentPosition
    const prepaidInterest = computedPI({
      interestGuaranteed: Number(positionStatus.interestGuaranteed),
      obligation: Number(positionStatus.obligation),
      epochDebt: Number(positionStatus.epochDebt),
      estimatedObligationToday: Number(positionStatus.estimatedObligationToday),
    })
    const totalAssetsValue = computedTC({
      collateralValue: Number(positionStatus.collateralValue),
      prepaidInterest,
    })
    const _minCollateralValue = TokenUtils.formatTokenAmount(
      totalAssetsValue - Number(positionStatus.shortBalanceValue) * minCollateralRatio,
      0,
    )
    return toLargeUnits(
      Number(_minCollateralValue),
      currentPosition.mintTokenInfo.settlementCurrency.decimals,
      true,
    ) as number
  }, [currentPosition])

  const newDisplayCollateralRatio = useMemo(() => {
    if (newCollateralRatio === Number.POSITIVE_INFINITY) {
      return `+${Infinite}`
    }
    if (newCollateralRatio === Number.NEGATIVE_INFINITY) {
      return `-${Infinite}`
    }
    return `${TokenUtils.formatTokenAmount(newCollateralRatio, 2)}%`
  }, [newCollateralRatio])
  const newDisplayPrepaidInterestCoverage = useMemo(() => {
    if (newPrepaidInterestCoverage === Number.POSITIVE_INFINITY) {
      return `+${Infinite}`
    }
    if (newPrepaidInterestCoverage === Number.NEGATIVE_INFINITY) {
      return `-${Infinite}`
    }
    return `${Math.floor(newPrepaidInterestCoverage)} days`
  }, [newPrepaidInterestCoverage])

  return {
    onUpdatePrepaidInterest,
    onUpdateCollateralValue,
    onUpdateNeutralizeValue,
    onUpdateTotalCollateralValue,
    newPrepaidInterestCoverage,
    actionPrepaidInterestVal,
    newCollateralRatio,
    newDisplayCollateralRatio,
    newDisplayPrepaidInterestCoverage,
    newNeutralizeFee,
    newShortBalanceValue,
    minCollateralValue,
    newEstimatedDailyDebt,
    newOutStandingDebt,
  }
}

export function usePositionAction() {
  const dispatch = useAppDispatch()
  const { currentPosition, currentMintToken } = usePositionInfo()
  const { account, chainId } = useWeb3React()
  const { toastError } = useToast()
  const { onUpdateWalletBalance } = useHashPowerToken()
  const { signer, accountContract: accountRegister, helperContract } = useSystemParams()
  const handleUpdatePosition = useCallback(
    async (mintToken?: SupportedMintTokens) => {
      await dispatch(
        fetchSinglePositionInfo({
          account,
          chainId,
          accountRegister,
          signer,
          helperContract,
          tokenKey: (mintToken as MintTokenKey) || (SupportedMintTokens[currentMintToken] as MintTokenKey),
        }),
      )
      onUpdateWalletBalance()
    },
    [account, accountRegister, chainId, currentMintToken, dispatch, helperContract, onUpdateWalletBalance, signer],
  )

  // flag = true from collateral add guarantee
  // flag = false from wallet  prepaid interest
  const handleUpdatePrepaidInterest = useCallback(
    async (transferVal, flag) => {
      try {
        console.log('transferVal', TokenUtils.formatDecimalString(transferVal, currentPosition.mintTokenInfo.settlementCurrency.decimals))
        const inter = new ethers.utils.Interface(ActionUser.abi)
        const data = inter.encodeFunctionData(ActionUserFunctionNames.AddGurrentee, [
          toSmallUnits(
            TokenUtils.formatDecimalString(transferVal, currentPosition.mintTokenInfo.settlementCurrency.decimals),
            currentPosition.mintTokenInfo.settlementCurrency.decimals,
          ),
          flag,
        ])
        console.log('currentPosition.positionAddress', currentPosition.positionAddress)
        const positionContract = new ethers.Contract(currentPosition.positionAddress, PositionABI, signer)
        return await positionContract.delegateCall(ActionUser.address[chainId], data)
      } catch (e: any) {
        toastError(ToastTitle.failed, e?.reason)
        throw e
      }
    },
    [chainId, currentPosition, signer, toastError],
  )

  const handleChangeCurrentPosition = useCallback(
    (mintToken) => {
      dispatch(updateCurrentMintToken(mintToken))
    },
    [dispatch],
  )

  const getLastEpochReward = useCallback(async () => {
    const { provider } = getProviderAndSigner(INFURA_NETWORK_URLS[chainId], currentPosition.positionAddress, chainId)
    const blockUpdaterContract = new ethers.Contract(
      currentPosition.mintTokenInfo.blockUpdater,
      BlockUpdaterABI,
      provider,
    )
    try {
      const result = await blockUpdaterContract.lastEpochReward()
      return Number(result)
    } catch (error) {
      console.error('get the lastEpochReward error', error)
      return 0
    }
  }, [currentPosition, chainId])
  return {
    handleUpdatePosition,
    onAddPrepaidInterest: handleUpdatePrepaidInterest,
    handleChangeCurrentPosition,
    getLastEpochReward,
  }
}
