import React from 'react';
import Svg from "../Svg";

function RoundArrow(props) {
  return (
    <Svg fill="none"  viewBox="0 0 24 24" {...props}>
        <circle cx="12" cy="12" r="11" stroke="#BED3E4" strokeWidth="2"/>
        <path d="M17.1078 12.8789H5.71222C5.31908 12.8789 5 12.4852 5 12C5 11.5148 5.31908 11.1211 5.71222 11.1211H17.1078C17.5009 11.1211 17.82 11.5148 17.82 12C17.82 12.4852 17.5009 12.8789 17.1078 12.8789Z" fill="#BED3E4"/>
        <path d="M17.9537 12.5048L14.2253 8.77647C13.8826 8.43369 13.8826 7.87647 14.2253 7.53369C14.5681 7.19092 15.1253 7.19092 15.4681 7.53369L19.1982 11.262C19.541 11.6048 19.541 12.162 19.1982 12.5048C18.8537 12.8493 18.2982 12.8493 17.9537 12.5048Z" fill="#BED3E4"/>
        <path d="M14.2256 15.2238L17.9539 11.4955C18.2967 11.1527 18.8539 11.1527 19.1967 11.4955C19.5394 11.8382 19.5394 12.3955 19.1967 12.7382L15.4684 16.4666C15.1256 16.8093 14.5684 16.8093 14.2256 16.4666C13.8828 16.1238 13.8828 15.5666 14.2256 15.2238Z" fill="#BED3E4"/>
    </Svg>
  );
}

export default RoundArrow;
