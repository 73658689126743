/**
 * @description Compute the collateral ratio
 * @param {Object} config - The configuration object
 * @param {number} config.totalCollateralValue - The value of totalCollateralValue
 * @param {number} config.shortBalanceValue - The value of shortBalanceValue
 * @returns {number} The collateral ratio.
 * @example
 * computedCR({ totalCollateralValue: 100, shortBalanceValue: 50 }) // 200
 */
export function computedCR(config: { totalCollateralValue: number; shortBalanceValue: number; }):number {
  const { totalCollateralValue, shortBalanceValue } = config;
  if (totalCollateralValue === 0) return 0
  return totalCollateralValue / shortBalanceValue * 100
}

/**
 * @description Compute the collateral ratio
 * @param {Object} config - The configuration object
 * @param {number} config.obligation - The value of obligation
 * @param {number} config.epochDebt - The value of value epochDebt
 * @param {number} config.estimatedObligationToday - The value of value estimatedObligationToday
 * @returns {number} The prepaid interest.
 * 
 */
export function computedPI(config: { interestGuaranteed: number; obligation: number; epochDebt: number; estimatedObligationToday: number; }): number {
  const { interestGuaranteed, obligation, epochDebt, estimatedObligationToday } = config;
  return interestGuaranteed - obligation - epochDebt - estimatedObligationToday;
}

/**
 * @description Compute the Prepaid Interest Coverage
 * @param {Object} config - The configuration object
 * @param {number} config.interestGuaranteed - The value of interestGuaranteed
 * @param {number} config.obligation - The value of obligation
 * @param {number} config.epochDebt - The value of epochDebt
 * @param {number} config.estimatedObligationToday - The value of estimatedObligationToday
 * @param {number} config.estimatedDailyDebt - The value of estimatedDailyDebt
 * @returns {number} The Prepaid Interest Coverage.
 */
export function computedPIC(config: { prepaidInterest: number, estimatedDailyDebt: number; }): number {
  const { prepaidInterest, estimatedDailyDebt } = config;
  if (prepaidInterest === 0) return 0;
  return prepaidInterest / estimatedDailyDebt;
}

/**
 * Computes the total collateral by adding the collateral value and prepaid interest.
 *
 * @param {Object} config - The configuration object containing the financial values.
 * @param {number} config.collateralValue - The value of the collateral.
 * @param {number} config.prepaidInterest - The amount of prepaid interest.
 * @returns {number} The total collateral, which is the sum of the collateral value and prepaid interest.
 */
export function computedTC(config: { collateralValue: number; prepaidInterest: number; }): number {
  const { collateralValue, prepaidInterest } = config;
  return collateralValue + prepaidInterest;
}

