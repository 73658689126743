import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" fill="none" {...props}>
      <circle cx="9" cy="9" r="8.4" stroke="#6ED6F3" strokeWidth="1.2"/>
      <path
        d="M6.84263 8.35025H11.1827C11.1878 8.35025 11.1941 8.35025 11.1992 8.34898C11.2551 8.3401 11.2919 8.28807 11.283 8.23223L10.7728 5.08503C10.7652 5.03553 10.7221 5 10.6726 5H7.35279C7.30329 5 7.26015 5.03553 7.25253 5.08503L6.74238 8.23223C6.74111 8.2373 6.74111 8.24365 6.74111 8.24873C6.74111 8.30456 6.7868 8.35025 6.84263 8.35025ZM7.99999 5.86294H10.0241L10.2868 7.48731H7.73603L7.99999 5.86294V5.86294ZM8.03172 9.4505C8.0241 9.40101 7.98096 9.36547 7.93146 9.36547H4.61167C4.56218 9.36547 4.51903 9.40101 4.51142 9.4505L4.00127 12.5977C4 12.6028 4 12.6091 4 12.6142C4 12.67 4.04569 12.7157 4.10152 12.7157H8.44162C8.44669 12.7157 8.45304 12.7157 8.45811 12.7145C8.51395 12.7056 8.55075 12.6535 8.54187 12.5977L8.03172 9.4505V9.4505ZM4.99619 11.8528L5.25888 10.2284H7.28299L7.54568 11.8528H4.99619ZM13.9987 12.5977L13.4886 9.4505C13.4809 9.40101 13.4378 9.36547 13.3883 9.36547H10.0685C10.019 9.36547 9.97588 9.40101 9.96826 9.4505L9.45811 12.5977C9.45684 12.6028 9.45684 12.6091 9.45684 12.6142C9.45684 12.67 9.50253 12.7157 9.55837 12.7157H13.8985C13.9035 12.7157 13.9099 12.7157 13.915 12.7145C13.9695 12.7056 14.0076 12.6535 13.9987 12.5977V12.5977ZM10.453 11.8528L10.7157 10.2284H12.7398L13.0025 11.8528H10.453Z"
        fill="#6ED6F3" stroke="#6ED6F3" strokeWidth="0.2"/>

    </Svg>
  )
}

export default Icon
