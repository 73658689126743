import { utils, BigNumber, BigNumberish } from 'ethers'
// import Decimal from 'decimal.js';

// BigNumber.config({
/**
 * @description: Format the wei unit number as a decimal string representing a unit number
 */
export function toLargeUnits(
  val: number | BigNumber,
  unitName?: BigNumberish | string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toNumber?: boolean,
): string | number {
  // return toNumber ? Number(utils.formatUnits(val, unitName)) : utils.formatUnits(val, unitName)
  return TokenUtils.formatDecimalString(String((val as any) / 10 ** Number(unitName)), Number(unitName))
}

/**
 * @description: Parses a valueString representing a number of units into a BigNumber instance representing a number of wei units
 */
export function toSmallUnits(val: string, unitName?: BigNumberish): BigNumber {
  return utils.parseUnits(val, unitName)
}
export class TokenUtils {
  /**
   * Formats a token amount with specified decimals.
   * @param amount - The amount to format.
   * @param decimals - The number of decimals to format the amount to.
   * @returns The formatted token amount as a string.
   */
  static formatTokenAmount(amount: number, decimals: number): string {
    const factor = 10 ** decimals
    const formattedAmount = Math.floor(amount * factor) / factor
    const res = formattedAmount.toFixed(decimals)
    return Number.isNaN(formattedAmount) ? '0' : res
  }

  static formatTokenAmountToNumber(amount: number, decimals: number): number {
    const factor = 10 ** decimals
    const formattedAmount = Math.floor(amount * factor) / factor
    const res = formattedAmount.toFixed(decimals)
    return Number.isNaN(formattedAmount) ? 0 : Number(res)
  }

  /**
   * Formats a currency amount to two decimal places.
   * @param amount - The amount to format.
   * @returns The formatted currency amount as a string.
   */
  static formatCurrencyTwoDecimals(amount: number): string {
    return this.formatTokenAmount(amount, 2)
  }

  /**
   * Formats a currency amount to four decimal places.
   * @param amount - The amount to format.
   * @returns The formatted currency amount as a string.
   */
  static formatFourDecimals(amount: number): string {
    return this.formatDecimalString(String(amount), 4)
  }

  /**
   * Returns the original amount without any formatting.
   * @param amount - The amount to return.
   * @returns The original amount.
   */
  static getOriginalAmount(amount: number): number {
    return amount
  }

  /**
   * Formats a number with appropriate units (K for thousands, M for millions).
   * @param val - The number to format.
   * @returns The formatted number with units as a string.
   */
  static formatNumberWithUnits(val: number): string {
    if (val >= 1_000_000) {
      return `${this.formatCurrencyTwoDecimals(val / 1_000_000)}M`
    }
    if (val >= 10_000) {
      return `${this.formatCurrencyTwoDecimals(val / 1_000)}K`
    }

    // Use formatCurrencyTwoDecimals to ensure two decimal places for small numbers
    return this.formatCurrencyTwoDecimals(val)
  }

  static formatDecimalString(numStr: string | number, decimalPlaces: number): string {
    // if the input is a number, convert it to a string
    let num = typeof numStr === 'number' ? numStr.toString() : numStr

    // if the input is in scientific notation, convert it to a string
    if (num.includes('e')) {
      const isNegative = Number(num) < 0
      if (isNegative) {
        num = num.slice(1)
      }
      // 使用正则处理科学计数法
      const match = num.match(/([\d.]+)e([-+]\d+)/)
      if (match) {
        const base = match[1].replace('.', '') // 基数部分（去掉小数点）
        const exponent = parseInt(match[2]) // 指数部分

        // 处理指数为负的情况，把数字移动到小数点后
        if (exponent < 0) {
          const zeros = '0'.repeat(Math.abs(exponent) - 1)
          num = `0.${  zeros  }${base}`
        } else {
          // 正指数直接在整数部分后面补零
          const zeros = '0'.repeat(exponent - (base.length - 1))
          num = base + zeros
        }
        if(isNegative) {
          num = `-${num}`
        }
      }
    }

    // check if the input is a valid number
    if (Number.isNaN(Number(num))) {
      throw new Error('invalid number')
    }

    // split the number into integer and decimal parts
    // eslint-disable-next-line prefer-const
    let [integerPart, decimalPart = ''] = num.split('.')

    // if decimalPlaces is 0, return the integer part only
    if (!decimalPart) {
      decimalPart = '0'.repeat(decimalPlaces)
    } else if (decimalPart.length > decimalPlaces) {
      // 如果小数部分超过需要的位数，截取
      decimalPart = decimalPart.slice(0, decimalPlaces)
    } else {
      // 如果小数部分不足，补齐0
      decimalPart = decimalPart.padEnd(decimalPlaces, '0')
    }

    // 如果 decimalPlaces 是 0，返回不带小数点的整数部分
    if (decimalPlaces === 0) {
      return integerPart
    }

    // 返回格式化后的数字
    return `${integerPart}.${decimalPart}`
  }
}
