import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 22 22"{...props} fill="none" >
      <circle cx="11" cy="11" r="11" fill="url(#b18b5169-162c-4646-9714-bab700018f40)"/><path d="M14.4804 10.0202C14.6365 8.96942 13.8422 8.40456 12.7562 8.02774L13.1085 6.60435L12.2483 6.38842L11.9054 7.7743C11.6792 7.71754 11.447 7.66399 11.2162 7.61094L11.5616 6.21593L10.702 6L10.3494 7.4229C10.1623 7.37996 9.97854 7.33751 9.80019 7.29285L9.80117 7.2884L8.61495 6.99006L8.38613 7.91546C8.38613 7.91546 9.02432 8.06278 9.01084 8.07191C9.35921 8.15951 9.42218 8.39173 9.41164 8.57582L9.01035 10.1974C9.03436 10.2035 9.06548 10.2124 9.09977 10.2262C9.07111 10.2191 9.04049 10.2112 9.00888 10.2035L8.4464 12.4751C8.40377 12.5817 8.29573 12.7416 8.05221 12.6809C8.06079 12.6935 7.42701 12.5237 7.42701 12.5237L7 13.5155L8.11934 13.7966C8.32758 13.8491 8.53165 13.9042 8.73254 13.956L8.37658 15.3957L9.23574 15.6116L9.58828 14.1872C9.82297 14.2514 10.0508 14.3106 10.2737 14.3664L9.92244 15.7841L10.7826 16L11.1385 14.563C12.6053 14.8426 13.7082 14.7299 14.1725 13.3936C14.5465 12.3176 14.1538 11.697 13.3821 11.2923C13.9441 11.1618 14.3675 10.7894 14.4804 10.0202ZM12.5151 12.7961C12.2493 13.8721 10.4509 13.2904 9.8678 13.1446L10.3401 11.2373C10.9232 11.3839 12.7929 11.6741 12.5151 12.7961ZM12.7812 10.0046C12.5386 10.9833 11.0418 10.4861 10.5562 10.3642L10.9845 8.63431C11.47 8.75621 13.0338 8.98374 12.7812 10.0046Z" fill="white"/><path d="M14 4.67358C16.3649 5.79705 18 8.20756 18 11C18 13.7923 16.3649 16.2029 14 17.3263M8 4.67358C5.63505 5.79705 4 8.20756 4 11C4 13.7923 5.63505 16.2029 8 17.3263" stroke="white" strokeWidth="1.2" strokeLinecap="round"/><defs><linearGradient id="b18b5169-162c-4646-9714-bab700018f40" x1="13" y1="23" x2="10.5" y2="-0.5" gradientUnits="userSpaceOnUse"><stop stopColor="#1BCCD8"/><stop offset="1" stopColor="#0094FF"/></linearGradient></defs>
    </Svg>
  )
}

export default Icon
