import { BG_COMMON_COLOR } from 'components/Menu/config'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 30px;
  background-color: ${BG_COMMON_COLOR};
  backdrop-filter: blur(27px);
  border-radius: 8px;
`

function Card(props) {
  const { children } = props
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Card
