import { createReducer } from '@reduxjs/toolkit'
import {tokensSymbol} from 'config/constants/types'
import { updataLiquidationList } from './action'
import {LiquidationProps} from './type'

export interface liquidationState{
  list : LiquidationProps[]
}

const initalState:liquidationState = {
  list: [
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
    {
      avatar: '0x012..121',
      ticker: tokensSymbol.FBTC20,
      markPrice: '$33',
      collateralMarkPrice: '$2000.23',
      collateralMarketValue: '$2000',
      outstandingDebtPosition: 200,
      collateralRatio: '100%',
      guaranteeDays: 85,
    },
  ],
}


export default createReducer(initalState, (builder)=>{
  return builder.addCase(updataLiquidationList, (state, {payload})=>{
    state.list = payload.list;
  })

})
