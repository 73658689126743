// shortSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum TokenType {
  SHORT = 'Short',
  NEUTRALIZE = 'Neutralize',
  SUPPLY = 'Supply',
  WithDraw = 'Withdraw',
}

export enum ActionType {
  ADD = 'add',
  REMOVE = 'remove',
}

export interface PendingAction {
  tokenType: TokenType;
  token: string;
  amount: number;
  price: number;
  type: ActionType;
  neutralizeType?: string;
}

export interface NeetralizePendingAction {
  tokenType: TokenType;
  token: string;
  amount: number;
  price: number;
  type: ActionType;
  neutralizeType: string;
}
export type NeutralizeType = 'wallet' | 'avatar';

export interface PendingActionState {
  activeToken: string;
  activeAmount: any;
  activeInfo: {
    token?: string;
    tokenType?: TokenType;
  };
  activeTab: number;
  activeIndex: number;
  pendingActions: Map<string, PendingAction[]>;
  rateInfo: {
    rate: any;
    coverage: any;
  };
  neutralizeType: NeutralizeType;
}

const initialState: PendingActionState = {
  activeToken: '',
  activeAmount: '',
  activeIndex: -1,
  activeInfo: {},
  pendingActions: new Map(),
  activeTab: 1,
  rateInfo: {
    rate: 0,
    coverage: 0,
  },
  neutralizeType: 'wallet',
};

const shortSlice = createSlice({
  name: 'short',
  initialState,
  reducers: {
    updateShortActiveToken(state, action: PayloadAction<{ activeToken: string }>) {
      state.activeToken = action.payload.activeToken;
    },
    updateActionInfo(state, action: PayloadAction<{ activeInfo: { token?: string; tokenType?: TokenType } }>) {
      state.activeInfo = action.payload.activeInfo;
    },
    updateActiveActionIndex(state, action: PayloadAction<{ index: number }>) {
      state.activeIndex = action.payload.index;
    },
    updateActiveAmount(state, action: PayloadAction<{ amount: any }>) {
      state.activeAmount = action.payload.amount;
    },
    setShortPendingAction(state, action: PayloadAction<{ actions: Map<string, PendingAction[]> }>) {
      state.pendingActions = action.payload.actions;
    },
    updateShortRateInfo(state, action: PayloadAction<{ rateInfo: { rate: any; coverage: any } }>) {
      state.rateInfo = action.payload.rateInfo;
    },
    updateActiveTab(state, action: PayloadAction<{ index: number }>) {
      state.activeTab = action.payload.index;
    },
    onChangeNeutralizeType(state, action: PayloadAction<{ neutralizeType: NeutralizeType }>) {
      state.neutralizeType = action.payload.neutralizeType;
    }
  },
});

export const {
  updateShortActiveToken,
  updateActionInfo,
  updateActiveActionIndex,
  updateActiveAmount,
  setShortPendingAction,
  updateShortRateInfo,
  updateActiveTab,
  onChangeNeutralizeType
} = shortSlice.actions;

export default shortSlice.reducer;
