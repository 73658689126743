import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useWeb3React } from 'web3-react-core'
import { withRouter } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { links } from '../config'
import { useWalletModal } from '../../uikit/components/WalletModal'

const Wrapper = styled('ul')`
  padding-top: 41px;
  padding-left: 28px;

  li {
    display: block;
    list-style: none;
  }

  > li {
    margin-bottom: 18px;
  }

  li > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 16px;

    position: relative;
    height: 48px;
    width: 244px;

    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    list-style: none;
    cursor: pointer;
    color: #000000;
    transition: 0.3s;

    img {
      margin-right: 20px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.04);
      border-radius: 11px;

      &:before {
        //content: ;
        position: absolute;
        background: linear-gradient(360deg, #7ae8f1 0%, #29a0ff 97.5%);
        box-shadow: 0 0 5px 3px rgba(117, 233, 242, 0.3);
        border-radius: 11px;
        width: 4px;
        height: 20px;
      }
    }
  }

  li li div {
    width: 221px;
    margin-left: 23px;
    font-family: 'Heebo Medium';
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;

    span {
      margin-left: 29px;
      color: #000;
    }
  }

  div.active {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    &:before {
      //content: '';
      position: absolute;
      background: linear-gradient(360deg, #7ae8f1 0%, #29a0ff 97.5%);
      box-shadow: 0 0 5px 3px rgba(117, 233, 242, 0.3);
      border-radius: 11px;
      width: 4px;
      height: 20px;
    }
  }
`
export const Icon = styled('img')`
  width: 53px;
  height: 45px;
`

const Menu = (props) => {
  const { account, active } = useWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, account)
  const { location, history, style } = props
  const [currentUrl, setCurrentUrl] = useState(location.pathname || '')
  useEffect(() => {
    if (location.pathname) {
      setCurrentUrl(location.pathname)
    }
  }, [location])
  const onChange = useCallback(
    (info) => {
      if (info.href === location.pathname) {
        return
      }
      setCurrentUrl(info.href)
      history.push(info.href)
      if (!active) {
        onPresentConnectModal()
      }
    },
    [active, history, location.pathname, onPresentConnectModal],
  )
  return (
    <Wrapper style={style}>
      {links.map((it) => {
        const children =
          (it.children && (
            <ul key={`${it.href}qwe`}>
              {it.children.map((item) => {
                return (
                  <li>
                    <div
                      key={item.href}
                      data-href={item.href}
                      onClick={(e) => {
                        e.stopPropagation()
                        onChange(item)
                      }}
                      onKeyUp={() => null}
                      tabIndex={0}
                      role="button"
                      className={
                        currentUrl === item.href || (item.label === 'Collateral' && item.href.includes('manage'))
                          ? 'active'
                          : ''
                      }
                    >
                      <span> {item.label}</span>
                    </div>
                  </li>
                )
              })}
            </ul>
          )) ||
          null

        return (
          <li key={`${it.href}qwe`} data-href={it.href}>
            <div
              onClick={() => {
                onChange(it)
              }}
              onKeyUp={() => null}
              role="button"
              tabIndex={0}
              className={
                (currentUrl && currentUrl === it.href) ||
                (it.label === 'Collateral' && currentUrl.includes('/manage' || '/collateral')) ||
                (it.label === 'Mint' && currentUrl.startsWith('/mint'))
                  ? 'active'
                  : '' || (it.label === 'Quick Mode' && currentUrl.startsWith('/quickMode') ? 'active' : '')
              }
            >
              <it.icon width="32" height="32" style={{ fill: 'none' }} />
              <span>{it.label}</span>
            </div>
            {children}
          </li>
        )
      })}
    </Wrapper>
  )
}

function Slider(props) {
  return (
    <div>
      <Menu {...props} />
    </div>
  )
}

export default withRouter(Slider)
