import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
        <circle cx="12" cy="12" r="11" stroke="#6ED6F3" strokeWidth="2"/>
        <path d="M10.0001 18L6 14.1599H18.0002" stroke="#6ED6F3" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0002 6L18.0002 9.84006H6.00006" stroke="#6ED6F3" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Icon
