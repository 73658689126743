import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="46" height="46" fill="none" viewBox="0 0 46 46" {...props}>
      <g id="Group 45">
        <circle id="Ellipse 104" cx="23" cy="23" r="23" fill="#DAF1FF"/>
        <path id="Vector 105" d="M13 25.3333L19.4681 32L32 17" stroke="#049CFA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </Svg>
  )
}

export default Icon
