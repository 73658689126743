import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 97 96" fill="none" {...props}>
        <path d="M48.7202 95C58.0159 95 67.1029 92.2435 74.832 87.0791C82.5611 81.9146 88.5852 74.5743 92.1425 65.9861C95.6999 57.398 96.6306 47.9479 94.8171 38.8308C93.0036 29.7137 88.5273 21.3391 81.9542 14.766C75.3811 8.19293 67.0066 3.71662 57.8895 1.90311C48.7723 0.0896077 39.3222 1.02036 30.7341 4.57768C22.146 8.135 14.8056 14.1591 9.64114 21.8882C4.47672 29.6173 1.72021 38.7043 1.72021 48C1.72021 60.4652 6.67199 72.4198 15.4862 81.234C24.3004 90.0482 36.255 95 48.7202 95Z" fill="#22A079"/>
        <path d="M54.3608 51.9146V51.9072C54.0442 51.9293 52.4095 52.025 48.7718 52.025C45.8631 52.025 43.8234 51.9441 43.1017 51.9072V51.9146C31.9162 51.4212 23.5731 49.4772 23.5731 47.1429C23.5731 44.816 31.9236 42.8646 43.1017 42.3712V49.9779C43.8307 50.0295 45.9294 50.1547 48.8233 50.1547C52.299 50.1547 54.0368 50.0074 54.3608 49.9779V42.3786C65.5243 42.8793 73.8453 44.8233 73.8453 47.1503C73.8453 49.4772 65.5169 51.4212 54.3608 51.922V51.9146ZM54.3608 41.5906V34.7866H69.9351V24.4111H27.5348V34.7866H43.1091V41.5906C30.4508 42.1724 20.9369 44.6761 20.9369 47.6805C20.9369 50.6849 30.4582 53.1885 43.1091 53.7776V75.589H54.3682V53.7776C67.0044 53.1959 76.5036 50.6922 76.5036 47.6878C76.5036 44.6908 67.0044 42.1797 54.3682 41.598" fill="#FEFEFE"/>
    </Svg>
  )
}

export default Icon
