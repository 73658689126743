import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { CHAIN_INFO, SupportedChainId ,INFURA_NETWORK_URLS} from '../config/constants/chains'

interface SwitchNetworkArguments {
  connector: any
  chainId: SupportedChainId
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
export default async function switchToNetwork({ connector, chainId }: SwitchNetworkArguments): Promise<null | void> {
  connector
    .getProvider()
    .then(async (res) => {
      const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString())
      try {
        await res.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: formattedChainId }],
        })
      } catch (error:any) {
        // 4902 is the error code for attempting to switch to an unrecognized chainId
        if (error.code === 4902) {
          const info = CHAIN_INFO[chainId]
          await res.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: formattedChainId,
                chainName: info.label,
                rpcUrls:[INFURA_NETWORK_URLS[SupportedChainId.BLAST_SEPPLIA]],
                nativeCurrency: info.nativeCurrency,
                blockExplorerUrls: [info.explorer],
              },
            ],
          })
          try {
            await res.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: formattedChainId }],
            })

            window.location.reload()
          } catch (errors) {
            console.debug('Added network but could not switch chains', errors)
          }
        } else {
          throw error
        }
      }
    })
    .catch(console.error)
}
