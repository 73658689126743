import Assets from 'assets'
import { ethers } from 'ethers'
import { SupportedChainId } from './chains'
import { blastSepolia, ChainConfig, SupportedChains, SupportedTokens, TokenConfig } from './tokenConfig'

interface MintToken extends TokenConfig {
  settlementCurrency: TokenConfig,
  blockUpdater: string,
  instrument: string,
}
enum SupportedMintTokens {
  FBTC20 = SupportedTokens.FBTC20,
  FBTC25 = SupportedTokens.FBTC25,
  FETH = SupportedTokens.FETH,
}
type MintTokenKey = keyof typeof SupportedMintTokens

interface MintTokenChainConfig extends Omit<ChainConfig, 'tokens'> {
  tokens: Record<MintTokenKey, MintToken>
}
const blastSepoliaMintTokens:Record<MintTokenKey, MintToken> = {
  FBTC20: {
    ...blastSepolia.FBTC20,
    settlementCurrency: blastSepolia.WBTC,
    blockUpdater: '0xf6BD26A86DA8A8A9Cf667a574d7F25994061C44d',
    instrument: '0x68e6958bCb7F2e1E87a872DdE341f8cBE7D52bC6',
  },
  FBTC25: {
    ...blastSepolia.FBTC25,
    settlementCurrency: blastSepolia.WBTC,
    blockUpdater: '0x7430192B6C7C2B55101e9f4B15EB911F0773398E',
    instrument: '0x0C10527c06828024CA2c1A993DdB3E922DFb8dE2',
  },
  FETH: {
    ...blastSepolia.FETH,
    settlementCurrency: blastSepolia.WETH,
    blockUpdater: '0xA2f75f8C008168eD3a8C95CCB95F82341Dc7Dc35',
    instrument: ethers.constants.AddressZero,
  },
}

// Aggregate the chain configurations
const mintTokenChainConfigs: Record<SupportedChainId, MintTokenChainConfig> = {
  [SupportedChainId.BLAST_SEPPLIA]: {
    chainName: SupportedChains.BlastSepolia,
    tokens: blastSepoliaMintTokens,
    logo: Assets.BLAST,
    chainId: SupportedChainId.BLAST_SEPPLIA,
  },
  // Add other chains as needed
}


const sortTokens = <T>(tokens: Record<string, T>): Record<string, T> => {
  const sortedTokens: Record<string, T> = {};
  if (Object.prototype.hasOwnProperty.call(tokens, 'WBTC')) {
    sortedTokens.WBTC = tokens.WBTC
  }
  if (Object.prototype.hasOwnProperty.call(tokens, 'WETH')) {
    sortedTokens.WETH = tokens.WETH;
  }
  if (Object.prototype.hasOwnProperty.call(tokens, 'USDT')) {
    sortedTokens.USDT = tokens.USDT;
  }

  Object.keys(tokens)
    .filter(symbol => symbol.startsWith('F(BTC'))
    .sort((a, b) => a.localeCompare(b))
    .forEach(symbol => {
      sortedTokens[symbol] = tokens[symbol];
    });

  Object.keys(tokens)
    .filter(symbol => symbol.startsWith('FETH'))
    .sort((a, b) => a.localeCompare(b))
    .forEach(symbol => {
      sortedTokens[symbol] = tokens[symbol];
    });
    

  return sortedTokens;
};

export { blastSepoliaMintTokens, SupportedMintTokens, mintTokenChainConfigs, sortTokens}
export type { MintToken, MintTokenKey }