import { SupportedChainId, INFURA_NETWORK_URLS } from 'config/constants/chains'
import { ethers, Contract } from 'ethers'

interface Call {
  target: string
  callData: string
  functionSignature: string
}

class Multicall {
  public provider: ethers.providers.Provider

  private multicallContract: Contract

  private abiCache: { [address: string]: ethers.utils.Interface }

  constructor(chainId: SupportedChainId, multicallAddress: string, multicallAbi: any) {
    this.provider = new ethers.providers.StaticJsonRpcProvider(INFURA_NETWORK_URLS[chainId])
    this.multicallContract = new ethers.Contract(multicallAddress, multicallAbi, this.provider)
    this.abiCache = {}
  }

  addAbi(address: string, abi: any) {
    this.abiCache[address] = new ethers.utils.Interface(abi)
  }

  private getReturnTypes(address: string, functionSignature: string) {
    const iface = this.abiCache[address]
    const fragment = iface.getFunction(functionSignature)
    return fragment.outputs
  }

  private static formatDecodedData<T extends Record<string, any[]>>(calls: Call[], decodedData: any[]): T {
    const result = {}
    calls.forEach((call, index) => {
      const functionName = call.functionSignature
      if (!result[functionName]) {
        result[functionName] = []
      }
      result[functionName].push(decodedData[index])
    })
    return result as T
  }

  async aggregate<T extends Record<string, any[]>>(calls: Call[]): Promise<{ blockNumber: number; returnData: T }> {
    try {
      const callTargets = calls.map((call) => ({ target: call.target, callData: call.callData }))
      const returnTypes = calls.map((call) => this.getReturnTypes(call.target, call.functionSignature))

      const [blockNumber, returnData] = await this.multicallContract.aggregate(callTargets)
      const decodedData = returnData.map((data, index) => ethers.utils.defaultAbiCoder.decode(returnTypes[index], data))
      return { blockNumber, returnData: Multicall.formatDecodedData<T>(calls, decodedData) }
    } catch (error) {
      console.error('Multicall aggregate error:', error)
      throw error
    }
  }
}

export default Multicall
