import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import ErrorIcon from '../Svg/Icons/Error'
import InfoIcon from '../Svg/Icons/Info'
import { Text } from '../Text'
import { IconButton } from '../Button'
import { CloseIcon, FailedIcon, SuccessIcon } from '../Svg'
import Flex from '../Box/Flex'
import { AlertProps, variants } from './types'

interface ThemedIconLabel {
  variant: AlertProps['variant']
  theme: DefaultTheme
  hasDescription: boolean
}

export const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return theme.colors.failure
    case variants.WARNING:
      return theme.colors.warning
    case variants.SUCCESS:
      return theme.colors.success
    case variants.INFO:
    default:
      return theme.colors.secondary
  }
}

const getIcon = (variant: AlertProps['variant'] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return FailedIcon
    case variants.WARNING:
      return ErrorIcon
    case variants.SUCCESS:
      return SuccessIcon
    case variants.INFO:
    default:
      return InfoIcon
  }
}
export const getText = (variant: AlertProps['variant'] = variants.INFO) => {
  return variant
}

const IconLabel = styled.div<ThemedIconLabel>`
  border-radius: 16px 0 0 16px;
  padding-right: 18px;
`

const withHandlerSpacing = 32 + 12 + 8 // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  /* padding-bottom: 12px;
  padding-left: 12px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : '12px')};
  padding-top: 12px; */
  line-height: 1.5;
`

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 8px;
  position: absolute;
  top: 8px;
`

const StyledAlert = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.alert.background};
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 32px 32px 24px;
`

const Alert: React.FC<AlertProps> = ({ title, children, variant, onClick }) => {
  const Icon = getIcon(variant)
  return (
    <StyledAlert>
      {variant === 'info' ? null : (
        <IconLabel variant={variant} hasDescription={!!children}>
          <Icon color="currentColor" width="24px" />
        </IconLabel>
      )}

      <Details
        hasHandler={!!onClick}
        style={{
          whiteSpace:
            (typeof children === 'string' || typeof title === 'string') && title.includes('ChainID') ? 'pre' : 'normal',
        }}
      >
        <Text style={{ marginBottom: 8 }} bold>
          {title}
        </Text>
        {typeof children === 'string' ? (
          <p
            style={{
              maxWidth: '300px',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {children}
          </p>
        ) : (
          children
        )}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon width="24px" color="currentColor" />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  )
}

export default Alert
