
export interface CollateralData {
  expanded: string,
  ratio: number,
  guarantee: number,
  FBTC20ApproveStatus: boolean
  currentCPT?: any
  collateralList: CollateralProps[]
  currentManagerAddress: string
}


export enum OpenStatus {
  notOpen,
  waiting,
  opened,
}

export interface CollateralProps {
  Ticker: string
  TokenAddress: string
  Liquidity: number | null
  OutstandingDebt: number | string | null
  CollateralRatio: number | string | null
  GuaranteeDays: number | string
  Status: OpenStatus
  Avatar: string | null | number
}
