import { createReducer } from '@reduxjs/toolkit'
import tokens from 'config/constants/tokens'
import {OpenStatus} from 'state/CollateralManage/type'
import {
  fetchCollateralList,
  toggleStatus,
  updateCollateralList,
  updateCurrentCPT,
  updateFBTC20ApproveStatus,
  updateGuarantee,
  updateRatio,
  updateCurrentManagerAddress,
} from './actions'
import { CollateralData } from './type'
import { SupportedChainId } from '../../config/constants/chains'

export const initialState: CollateralData = {
  expanded: 'panel1',
  ratio: 60,
  guarantee: 30,
  currentCPT: {
    position: 0,
    allowedAssets: [],
    avatarAssetList: {},
  },
  FBTC20ApproveStatus: false,
  currentManagerAddress: '',
  collateralList: [
    {
      Ticker: tokens['F(BTC,20)'].symbol,
      TokenAddress: tokens['F(BTC,20)'].address[SupportedChainId.BLAST_SEPPLIA],
      Liquidity: 0,
      OutstandingDebt: 0,
      CollateralRatio: 0,
      GuaranteeDays: 0,
      Avatar: '0x0000000000000000000000000000000000000000',
      Status: OpenStatus.notOpen,
    },
    {
      Ticker: tokens['F(BTC,25)'].symbol,
      TokenAddress: tokens['F(BTC,25)'].address[SupportedChainId.BLAST_SEPPLIA],
      Liquidity: 0,
      OutstandingDebt: 0,
      CollateralRatio: 0,
      GuaranteeDays: 0,
      Avatar: '0x0000000000000000000000000000000000000000',
      Status: OpenStatus.notOpen,
    },
  ],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(toggleStatus, (state, { payload }) => {
      state.expanded = payload.expanded
    })
    .addCase(updateRatio, (state, { payload }) => {
      state.ratio = payload.ratio
    })
    .addCase(updateGuarantee, (state, { payload }) => {
      state.guarantee = payload.guarantee
    })
    .addCase(fetchCollateralList, (state, { payload }) => {
      state.collateralList = payload.collateralList
    })
    .addCase(updateCurrentCPT, (state, { payload }) => {
      state.currentCPT = payload.data || {}
    })
    .addCase(updateCurrentManagerAddress, (state, { payload }) => {
      state.currentManagerAddress = payload.currentManagerAddress
    })
    .addCase(updateCollateralList, (state, { payload }) => {
      state.collateralList = payload.collateralList
    })
    .addCase(updateFBTC20ApproveStatus, (state, { payload }) => {
      state.FBTC20ApproveStatus = payload.status
    }),
)
