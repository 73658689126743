import BLAST from './icons/chain/blast.png'
import FBTC from './icons/tokens/FBTC.png'
import WBTC from './icons/tokens/WBTC.svg'
import USDT from './icons/tokens/USDT.svg'
import collateralManangP from './svg/collateralManangP.svg'
import Collateral from './svg/Collateral.svg'
import PrepaidInterest from './svg/PrepaidInterest.svg'
import WETH from './icons/tokens/WETH.png'
import FETH from './icons/tokens/FETH.svg'
import Wallet from './svg/wallet.svg'

export default {
    BLAST,
    FBTC,
    WBTC,
    USDT,
    WETH,
    FETH,
    CollateralManage: collateralManangP,
    Collateral,
    PrepaidInterest,
    Wallet,
}