export const _test_ = []
export const uniswapIframeUrl = '/swap/_renderer.html?_fixtureId=%7B%22path%22%3A%22src%2Fcosmos%2FSwap.fixture.tsx%22%2C%22name%22%3Anull%7D'

export const hedgingVersion = 'DEXV2'

export const Infinite = "∞"

export const StringZero = '0'

export const ToastTitle = {
  error: 'Error',
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
  submited: 'Submitted',
  failed: 'Failed',
}

export const ActionUserFunctionNames = {
  AddGurrentee: 'addGuarantee',
}

export const DataIndexName = {
  CollateralValue: 'Collateral Value',
  PrepaidInterest: 'Prepaid Interest',
  CollateralRatio: 'Collateral Ratio',
  PrepaidInterestCoverage: 'Prepaid Interest Coverage',
}

export const NeutralizeFee = 0.01 // 1% fee