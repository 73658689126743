import {useDispatch, } from 'react-redux'
import {useCallback} from "react";
import { useAppSelector } from 'state';
import {toggleNetworkStatus, toggleWalletStatus} from "./action";
import {statList} from "./reducer";



export function useToggleStatus(status: statList): boolean {
  return useAppSelector((state) => state.header[status]);
}

export function useToggleNetworkStatus(): () => void {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(toggleNetworkStatus())
  }, [dispatch])
}

export function useToggleWalletStatus(): () => void {
  const walletStatus = useAppSelector((state) => {
    const {networkStatus} = state.header;
    return networkStatus;
  });
  console.log(walletStatus);
  const dispatch = useDispatch()
  return useCallback(() => dispatch(toggleWalletStatus()), [dispatch])
}
