import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M19.5327 16.3906L10.5952 0.921875C10.462 0.691992 10.2321 0.578125 10.0001 0.578125C9.76802 0.578125 9.53599 0.691992 9.40494 0.921875L0.467439 16.3906C0.203181 16.8504 0.53404 17.4219 1.06256 17.4219H18.9376C19.4661 17.4219 19.7969 16.8504 19.5327 16.3906ZM9.31255 6.9375C9.31255 6.84297 9.3899 6.76562 9.48443 6.76562H10.5157C10.6102 6.76562 10.6876 6.84297 10.6876 6.9375V10.8906C10.6876 10.9852 10.6102 11.0625 10.5157 11.0625H9.48443C9.3899 11.0625 9.31255 10.9852 9.31255 10.8906V6.9375ZM10.0001 14.5C9.73019 14.4945 9.47325 14.3834 9.28434 14.1906C9.09544 13.9978 8.98964 13.7387 8.98964 13.4688C8.98964 13.1988 9.09544 12.9397 9.28434 12.7469C9.47325 12.5541 9.73019 12.443 10.0001 12.4375C10.2699 12.443 10.5269 12.5541 10.7158 12.7469C10.9047 12.9397 11.0105 13.1988 11.0105 13.4688C11.0105 13.7387 10.9047 13.9978 10.7158 14.1906C10.5269 14.3834 10.2699 14.4945 10.0001 14.5V14.5Z"
        fill="#DA1021"/>
    </Svg>
  )
}

export default Icon
