// src/App.tsx
import React from 'react'
import Menu from 'components/Menu'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePositionInit } from 'hooks/news/usePoistion'
import Routes from './routes'

const App: React.FC = () => {
  useEagerConnect()
  usePositionInit()
  return (
    <Menu>
      <Routes />
    </Menu>
  )
}

export default React.memo(App)
