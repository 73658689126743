import Assets from 'assets'
import { SupportedChainId } from './chains'

// Define the supported chains
enum SupportedChains {
  BlastSepolia = 'Blast Sepolia',
  // Add other chains as needed
}
enum SupportedTokens {
  FBTC20 = 'F(BTC,20)',
  FBTC25 = 'F(BTC,25)',
  FETH = 'FETH',
  WETH = 'WETH',
  WBTC = 'WBTC',
  USDT = 'USDT',
  // Add other tokens as needed
}

const valueToKeyMap: { [key: string]: keyof typeof SupportedTokens } = Object.keys(SupportedTokens).reduce(
  (map, key) => {
    const value = SupportedTokens[key as keyof typeof SupportedTokens]
    return {
      ...map,
      [value]: key as keyof typeof SupportedTokens,
    }
  },
  {} as { [key: string]: keyof typeof SupportedTokens },
)

type tokenKey = keyof typeof SupportedTokens
// Define the interface for token configuration
interface TokenConfig {
  symbol: SupportedTokens
  address: string
  decimals: number
  logo: string
}

// Define the interface for chain configuration
interface ChainConfig {
  chainName: SupportedChains
  logo: string
  chainId: SupportedChainId
  tokens: Record<tokenKey, TokenConfig>
}

const commonTokens: Record<tokenKey, Omit<TokenConfig, 'address'>> = {
  FBTC20: {
    symbol: SupportedTokens.FBTC20,
    decimals: 6,
    logo: Assets.FBTC,
  },
  FETH: { symbol: SupportedTokens.FETH, decimals: 6, logo: Assets.FETH },
  FBTC25: { symbol: SupportedTokens.FBTC25, decimals: 6, logo: Assets.FBTC },
  WETH: { symbol: SupportedTokens.WETH, decimals: 18, logo: Assets.WETH },
  WBTC: { symbol: SupportedTokens.WBTC, decimals: 8, logo: Assets.WBTC },
  USDT: { symbol: SupportedTokens.USDT, decimals: 6, logo: Assets.USDT },
  // Add other common tokens as needed
}

// Define the token configurations for each chain
const blastSepolia: Record<tokenKey, TokenConfig> = {
  FBTC20: {
    ...commonTokens.FBTC20,
    address: '0x982ebde77c10b7155a73d59c0437ac556f7f7b01',
  },
  FETH: { ...commonTokens.FETH, address: '0x1a5A59c69682d0bE488E4058D15278Bd250Be049', },
  FBTC25: { ...commonTokens.FBTC25, address: '0xdc5461ad03596f2d56c15967258c25d23319a89b' },
  WETH: { ...commonTokens.WETH, address: '0x5A5df35102695D6e60B91e9C3F4CAA1b2B45c77F' },
  WBTC: { ...commonTokens.WBTC, address: '0x684063ab93aa493f8a1389cd2fbc5e9fbd324a91' },
  USDT: { ...commonTokens.USDT, address: '0xbfec5826db705250f2af5cc7c66910879b8ef930' },
}

// Aggregate the chain configurations
const chainConfigs: Record<SupportedChainId, ChainConfig> = {
  [SupportedChainId.BLAST_SEPPLIA]: {
    chainName: SupportedChains.BlastSepolia,
    tokens: blastSepolia,
    logo: Assets.BLAST,
    chainId: SupportedChainId.BLAST_SEPPLIA,
  },
  // Add other chains as needed
}

const tokenAddresKey = (chainId: SupportedChainId): Record<string, TokenConfig> => {
  const chainConfig = chainConfigs[chainId]
  const tokens: {
    [K: string]: TokenConfig
  } = {}
  if (!chainConfig) return tokens
  Object.values(chainConfig.tokens).forEach((_token) => {
    tokens[_token.address.toLowerCase()] = _token
  })
  return tokens
}

// Export the configurations
export { valueToKeyMap, chainConfigs, SupportedChains, tokenAddresKey, SupportedTokens, commonTokens, blastSepolia, SupportedChainId }
export type { TokenConfig, ChainConfig, tokenKey }
