import {useEffect, useState} from 'react'
import {useWeb3React} from 'web3-react-core'
import {formatEther} from '@ethersproject/units'
import {BigNumber} from "@ethersproject/bignumber";


/**
 * @desc Get the balance of the current network token
 * */
export default function useCurrentBalance(ens?) {
  const [balance, setBalance] = useState<string | number>(-1)
  const {account, chainId, library} = useWeb3React()
  useEffect(() => {
    if (account && library) {
      (async () => {
        try {
          const _balance = await library.getBalance(ens || account);
          if (_balance) {
            setBalance(formatEther(_balance))
          }

          library.on('block', async () => {
            const _b = await library.getBalance(account);
            if (!_b.eq(BigNumber.from(_balance))) {
              const balanceInEth = formatEther(_b)
              setBalance(balanceInEth)
            }
          });
        } catch (e) {
          console.warn(e)
        }

      })();
    }
  }, [account, balance, chainId, ens, library])

  return balance
}
