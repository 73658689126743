import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 14" {...props} fill="none">
      <path
        d="M10.4997 3.49984C10.8091 3.49984 11.1058 3.62275 11.3246 3.84155C11.5434 4.06034 11.6663 4.35708 11.6663 4.6665V11.6665C11.6663 11.8197 11.6362 11.9714 11.5775 12.113C11.5189 12.2545 11.433 12.3831 11.3246 12.4915C11.2163 12.5998 11.0877 12.6857 10.9461 12.7444C10.8046 12.803 10.6529 12.8332 10.4997 12.8332H5.83301C5.6798 12.8332 5.52809 12.803 5.38655 12.7444C5.245 12.6857 5.11639 12.5998 5.00805 12.4915C4.89972 12.3831 4.81378 12.2545 4.75515 12.113C4.69652 11.9714 4.66634 11.8197 4.66634 11.6665V4.6665C4.66634 4.51329 4.69652 4.36159 4.75515 4.22004C4.81378 4.07849 4.89972 3.94988 5.00805 3.84155C5.11639 3.73321 5.245 3.64727 5.38655 3.58864C5.52809 3.53001 5.6798 3.49984 5.83301 3.49984H10.4997ZM10.4997 4.6665H5.83301V11.6665H10.4997V4.6665ZM8.74968 1.1665C8.90439 1.1665 9.05276 1.22796 9.16216 1.33736C9.27155 1.44675 9.33301 1.59513 9.33301 1.74984L9.33243 2.33317H3.49968L3.49909 10.4998H2.91634C2.76163 10.4998 2.61326 10.4384 2.50386 10.329C2.39447 10.2196 2.33301 10.0712 2.33301 9.9165V2.33317C2.33301 2.17996 2.36318 2.02825 2.42181 1.88671C2.48045 1.74516 2.56638 1.61655 2.67472 1.50821C2.78305 1.39988 2.91166 1.31394 3.05321 1.25531C3.19476 1.19668 3.34647 1.1665 3.49968 1.1665H8.74968Z"
        fill="#6ED6F3"/>
    </Svg>
  )
}

export default Icon
