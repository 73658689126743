import { WBTCIcon, Fbtc20Icon, USDTIcon } from 'components/uikit'
import { tokensSymbol } from './types'
import BlockUpdater from '../abi/BlockUpdater.json'
import { SupportedChainId } from './chains'



export enum tokenType {
  ERC20 = 'erc20',
  CPT = 'ComputingPowerToken',
}

enum TokenDecimals{
  WBTC = 8,
  USDT = 6,
  'F(BTC,20)'=6,
  'F(BTC,25)'=6,
}


export const TokensIcon = {
  'F(BTC,20)': './svg/fbtc20.svg',
  'F(BTC,25)': './svg/fbtc20.svg',
}




const tokens = {
  WBTC: {
    symbol: tokensSymbol.WBTC,
    type: tokenType.ERC20,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x684063aB93Aa493F8a1389cD2Fbc5E9fBd324A91',
    },
    icon: WBTCIcon,
    decimals: TokenDecimals.WBTC,
  },
  USDT: {
    symbol: tokensSymbol.USDT,
    oracleHubCallName: 'USDT-BTC',
    type: tokenType.ERC20,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xBfeC5826db705250f2AF5cC7C66910879b8EF930',
    },
    decimals:TokenDecimals.USDT,
    icon: USDTIcon,
  },
  'F(BTC,20)': {
    symbol: tokensSymbol.FBTC20,
    oracleHubCallName: 'F(BTC,20)-BTC',
    type: tokenType.CPT,
    icon: Fbtc20Icon,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x982Ebde77c10B7155A73d59c0437aC556F7F7b01',
    },
    decimals: TokenDecimals['F(BTC,20)'],
    blockUpdater: {
      abi: BlockUpdater,
      address: {
        [SupportedChainId.BLAST_SEPPLIA]: '0xf6BD26A86DA8A8A9Cf667a574d7F25994061C44d',
      },
    },
  },
  'F(BTC,25)': {
    symbol: tokensSymbol.FBTC25,
    oracleHubCallName: 'F(BTC,25)-BTC',
    type: tokenType.CPT,
    icon: Fbtc20Icon,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xdc5461ad03596f2D56C15967258c25D23319a89b',
    },
    decimals: TokenDecimals['F(BTC,25)'],
    blockUpdater: {
      abi: BlockUpdater,
      address: {
        [SupportedChainId.BLAST_SEPPLIA]: '0x7430192B6C7C2B55101e9f4B15EB911F0773398E',
      },
    },
  }
}

export const poolData = [
  {
    token: 'WBTC',
    balance: 0,
    symbol: 'WBTC',
    icon: WBTCIcon,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: tokens.WBTC.address[SupportedChainId.BLAST_SEPPLIA],
    },
    rightOptionList: [
      {
        token: 'WBTC',
        symbol: 'WBTC',
        icon: WBTCIcon,
        address: {
          [SupportedChainId.BLAST_SEPPLIA]: tokens.WBTC.address[SupportedChainId.BLAST_SEPPLIA],
        },
        balance: 0,
      },
    ],
  },
]

export default tokens
