import positionManager from '../abi/positionManager.json'
import OracleHub from '../abi/OracleHub.json'
import OracleHubV2 from '../abi/OracleHubV2.json'
import accountReg from '../abi/accountReg.json'
import MatrixAbi from '../abi/EfficiencyMatrix.json'
import FBTC20InitMint from '../abi/FBTC20initMint.json'
import ActionUser from '../abi/FBTC20Mint.json'
import HealthHelper from '../abi/HealthHelper.json'
import FaucetABI from '../abi/Faucet.json'
import BlockUpdater from '../abi/BlockUpdater.json'
import LiquidationABI from '../abi/LiquidationABI.json'
import IUniswapV2Router02ABI from '../abi/IUniswapV2Router02.json'
import ActionMintSell from '../abi/ActionMintSell.json'
import IUniswapV2Factory from '../abi/IUniswapV2Factory.json'
import IUniswapV2Pair from '../abi/IUniswapV2Pair.json'
import MintSellHelper from '../abi/MintSellHelper.json'
import Instrument from '../abi/instrument.json'
import Obsever from '../abi/Observer.json'
import ActionMulticall from '../abi/ActionMulticall.json'
import UniswapMulticall from '../abi/IUniswapMulticall.json'
import { SupportedChainId } from './chains'

export enum ContractType {
  Manager,
  Oracle,
  Hub,
  DMOUpdater,
}

export default {
  // router02
  uniswapV2Router20: {
    abi: IUniswapV2Router02ABI,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xcDf9136c2AB0DA6e556F28152A80a09e327fF9Cf',
    },
  },
  // ActionMintSell
  actionMintSell: {
    abi: ActionMintSell,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x9d72A10b5DB929262ED0cF6b322E138863378F63',
    },
  },
  // uniswap v2 factory
  uniswapV2Factory: {
    abi: IUniswapV2Factory,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x0A98e24c6bc15DE8493AA660f788Bb260fb4d1F9',
    },
  },
  // Obsever
  obsever: {
    abi: Obsever,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xF411AF7017b7BdFedDDA52207d0221426646E3C1',
    },
  },
  // FBTC:WBTC pair
  uniswapV2Pair: {
    abi: IUniswapV2Pair,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xAaC86476a1f2a15E1Aac5270b928d26eAF0ceEac',
    },
  },

  // instrument
  instrument: {
    abi: Instrument,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x68e6958bCb7F2e1E87a872DdE341f8cBE7D52bC6',
    },
    address2: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x0C10527c06828024CA2c1A993DdB3E922DFb8dE2',
    },
    // TODO
    //  [`${tokens['F(BTC,20)'].symbol}:${tokens.WBTC}`]: {
    //   address: {
    //     [SupportedChainId.BLAST_SEPPLIA]: 0x68e6958bCb7F2e1E87a872DdE341f8cBE7D52bC6
    //   }
    // }
    //  [`${tokens['F(BTC,25)'].symbol}:${tokens.WBTC}`]: {
    //   address: {
    //     [SupportedChainId.BLAST_SEPPLIA]: 0x0C10527c06828024CA2c1A993DdB3E922DFb8dE2
    //   }
    // }
  },

  // MintSellHelper
  mintSellHelper: {
    abi: MintSellHelper,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xd2A91038F7eAb691b78A8B9852aC95F3a64f24a5',
    },
  },
  // EfficiencyMatrix
  EfficiencyMatrix: {
    abi: MatrixAbi,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x36565c05753dE60f8A209bA664B21FC3a2b08528',
    },
  },
  manager: {
    // AccountRegister
    accountRegister: {
      abi: accountReg,
      address: {
        [SupportedChainId.BLAST_SEPPLIA]: '0xAa84eAF331F45921AAD7103EB604b85D3275Fbb1',
      },
    },
    // OracleRegister
    oracleRegister: {
      abi: OracleHubV2,
      type: ContractType.Hub,
      address: {
        [SupportedChainId.BLAST_SEPPLIA]: '0x151af137fe73f613F666C76BA775AbFC66f49EDb',
      },
    },
  },
  // ActionInitialMint
  fbtc20initMint: {
    abi: FBTC20InitMint,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xf87f51C7CB8D20Dd1D900D5b34AB757b8877084b',
    },
  },
  // ActionUser1
  ActionUser: {
    abi: ActionUser,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x723A18DB82dDcD003932C865a696374330bACC80',
    },
  },
  // HealthHelper
  helper: {
    abi: HealthHelper,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x88737d5e598B0b9C669011907B5aa23512F65409',
    },
  },
  // claimHelper
  claimHelper: {
    abi: ['function multipleClaim(tuple(address minerToken, address holder)[]  claims)'],
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x9772370C076CC0F36F909F5C3842b67Bb5eA06b7',
    },
  },
  // Faucet
  faucet: {
    abi: FaucetABI,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xf09aDA723315f6C81617F29f52C2cC8c3Cf0ed68',
    },
  },
  pair: {
    // FBTC20:WBTC pair
    'F(BTC,20)-WBTC': {
      [SupportedChainId.BLAST_SEPPLIA]: '0xAaC86476a1f2a15E1Aac5270b928d26eAF0ceEac',
      tokenAddress: [
        '0x982Ebde77c10B7155A73d59c0437aC556F7F7b01',
        '0x684063aB93Aa493F8a1389cD2Fbc5E9fBd324A91'
      ]
    },
    // FBTC25:WBTC pair
    'F(BTC,25)-WBTC': {
      [SupportedChainId.BLAST_SEPPLIA]: '0xe39dbd9edfb7eb6302efe4afe1b0d3bc9c6e0509',
      tokenAddress: [
        '0xdc5461ad03596f2D56C15967258c25D23319a89b',
        '0x684063aB93Aa493F8a1389cD2Fbc5E9fBd324A91'
      ]
    },
    // USDT:WBTC pair
    'USDT-WBTC': {
      [SupportedChainId.BLAST_SEPPLIA]: '0xF74A91BF19CE41898Fa825ea8Bb7759629975Fe1',
      tokenAddress: [
        '0xBfeC5826db705250f2AF5cC7C66910879b8EF930',
        '0x684063aB93Aa493F8a1389cD2Fbc5E9fBd324A91'
      ]
    },
  },
  // ActionLiquidator1
  liquidation: {
    abi: LiquidationABI,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x61b7f7286509414799746Ce00F44b379749Ea998',
    },
  },
  // BlockUpdater
  blockUpdater: {
    abi: BlockUpdater,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xf6BD26A86DA8A8A9Cf667a574d7F25994061C44d',
    },
  },
  // ActionMulticall
  ActionMulticall: {
    abi: ActionMulticall,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xc52cD87329ae2f23243E8874c6d1DFCfd349B6f0',
    },
  },

  /**
   *@deprecated not used
   * */
  positionManagerFBTC20: {
    abi: positionManager,
    symbol: 'F(BTC,20)',
    token: 'F(BTC,20)',
    shortName: 'FBTC20',
    oracleHubCallName: 'F(BTC,20)-BTC',
    type: ContractType.Manager,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0xc639d68C8d44b380104D112bE95fd9CBbDaC46ad',
    },
  },

  /**
   * @deprecated not used
  */
  oracleHub: {
    abi: OracleHub,
    type: ContractType.Hub,
    address: {
      [SupportedChainId.BLAST_SEPPLIA]: '0x151af137fe73f613F666C76BA775AbFC66f49EDb',
    },
  },

  uniswapV2Multicall: {
    address: '0xFA8fD4CAc93c3B398FD2e854CC2cCdbA8dFC94B1',
    abi: UniswapMulticall,
  }
}
