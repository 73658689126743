import {
  CollateralIcon,
  DashboardIcon,
  FaucetIcon,
  LiquidationIcon,
  MyPageIcon,
  // OnboardingIcon,
  TradeIcon,
  ShortNeutralizeIcon,
  HedgingIcon,
} from 'components/uikit'
import { LinkStatus } from './types'

export const status = {
  LIVE: <LinkStatus>{
    text: 'LIVE',
    color: 'failure',
  },
  SOON: <LinkStatus>{
    text: 'SOON',
    color: 'warning',
  },
  NEW: <LinkStatus>{
    text: 'NEW',
    color: 'success',
  },
}

export const links = [
  // {
  //   label: 'Onboarding',
  //   icon: OnboardingIcon,
  //   href: '/onboarding',
  // },
  {
    label: 'My Page',
    icon: MyPageIcon,
    href: '/mypage',
  },
  {
    label: 'Collateral',
    icon: CollateralIcon,
    href: '/collateral',
    children: [],
  },
  {
    label: 'Short & Neutralize',
    icon: ShortNeutralizeIcon,
    href: '/shortneutralize',
    children: [],
  },
  // {
  //   label: 'Mint',
  //   icon: MintIcon,
  //   href: '/mint',
  //   children: [],
  // },
  // {
  //   label: 'Trade',
  //   icon: TradeIcon,
  //   href: '/trade',
  //   children: [],
  // },
   {
    label: 'Hedging',
    icon: HedgingIcon,
    href: '/hedging',
    children: [],
  },
  {
    label: 'Buy Token',
    icon: TradeIcon,
    href: '/buytoken',
    children: [],
  },
  {
    label: 'Epoch Stat',
    icon: DashboardIcon,
    href: '/epochStat',
    children: [],
  },
  // {
  //   label: 'Quick Mode',
  //   icon: LiquidationIcon,
  //   href: '/quickMode',
  //   children: []
  // },
  {
    label: 'Liquidation',
    icon: LiquidationIcon,
    href: '/liquidation',
    children: [],
  },
  {
    label: 'Faucet',
    icon: FaucetIcon,
    href: '/faucet',
    children: [],
  }
]

export const socials = [
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    items: [
      {
        label: '',
        href: '',
      },
    ],
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: '',
  },
]

export const MENU_HEIGHT = 84
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 283
export const SIDEBAR_WIDTH_REDUCED = 56
export const BG_COMMON_COLOR = 'rgba(255,255,255,.6)'
