import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import useEpoch from 'hooks/useEpoch'

const Wrapper = styled('div')`
  width: 230px;
  border-top: 1px solid #aec1d5;
  margin-left: 53px;
  padding: 27px 0 45px;
  h2 {
    font-family: PingFangSC-Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 9px;
  }
  p {
    font-family: PingFangSC-Medium, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.6);
  }
`
const ButtonWrap = styled(Button)`
  text-transform: none;
  padding: 7px 0;
  width: fit-content;
  min-width: fit-content;
  color: #2aa1ff;
  font-family: PingFangSC-Medium, sans-serif;
  font-weight: 500;
  font-size: 14px;
`

function EpochStatus() {
  const {currentEpoch, prevEpoch} = useEpoch();
  return (
    <Wrapper>
      <h2>Current Epoch {currentEpoch.epoch}</h2>
      <p>Epoch: {Number(prevEpoch.epoch)}</p>
      <p>Start: {prevEpoch.start}</p>
      <p>Daily Mining Output: {prevEpoch.factor}</p>
      <Link to="/epochStat">
        <ButtonWrap disableRipple disableFocusRipple disableTouchRipple>
          More
        </ButtonWrap>
      </Link>
    </Wrapper>
  )
}

export default EpochStatus
