import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Button as CustomButton, LogoIcon } from 'components/uikit'
import Card from 'components/Card'

const HomeWrap = styled('div')`
  background: url('/svg/homebg.svg') center / cover fixed no-repeat;
  background-clip: content-box;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  overflow: scroll;
`
const Content = styled('div')`
  //width: 80%;
  margin: 100px auto 100px;
  width: 1071px;
`
const HeaderWrapper = styled(Card)`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 102px;
  padding: 30px 0 !important;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 54px;
  }

  .nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    a {
      font-family: PingFangSC-Medium, sans-serif;
    }
    li:not(:last-child) {
      margin-right: 40px;
    }
  }
`

const MainWrapper = styled(Card)`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: unset !important;
  padding: 56px !important;
  margin: 2px 0;

  .content {
    display: flex;
    justify-content: space-between;
    color: #000000;

    h1 {
      font-family: PingFangSC-Semibold, sans-serif;
      font-weight: 550;
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 16px;
    }

    p {
      width: 480px;
      font-family: PingFangSC-Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 38px;
    }

    .launchapp {
      box-shadow: none;
      background: linear-gradient(270deg, #7ae9f0 0%, #61c3f7 100%);
      color: #000;
      border-radius: 12px;
      height: 44px;
      line-height: 44px;
      font-family: PingFangSC-Medium, sans-serif;
      font-weight: 500;
      font-size: 20px;
      width: 205px;
      margin-bottom: 67px;
    }

    .video_img {
      border-radius: 10px;
      width: 227px;
      height: 113px;
      margin-top: 13px;
      padding: 0;
      overflow: hidden;
    }
  }

  .contactus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`
const FooterWrapper = styled('footer')`
  display: flex;
  .cardItem {
    padding: 70px 52px 96px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: unset !important;
    width: calc(100% / 3);
    &:first-of-type {
      border-bottom-left-radius: 8px !important;
    }

    &:nth-of-type(2) {
      margin: 0 2px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px !important;
    }
    h4 {
      font-family: PingFangSC-Medium, sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 20px;
    }

    p {
      font-family: PingFangSC-Regular, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #000000;
    }
  }
`

function HomePage() {
  return (
    <HomeWrap>
      <Content>
        <HeaderWrapper>
          <header className="content">
            <LogoIcon />
            <nav className="nav">
              <ul>
                <li>
                  <a target="_blank" href="https://docsend.com/view/h9kybigmwtctge7v" rel="noreferrer">
                    Whitepaper
                  </a>
                </li>
                {/* <li><Link to='/'>Career</Link></li> */}
              </ul>
            </nav>
          </header>
        </HeaderWrapper>
        <MainWrapper>
          <div className="content">
            <div className="detail">
              <h1>MappingFunk Protocol</h1>
              <p>Mint, trade, earn, and hedge on the MappingFunk Marketplace with hashpower-native derivatives</p>
              <Link to="/mypage">
                <CustomButton className="launchapp">Launch App</CustomButton>
              </Link>
            </div>
            {/* <div className='contactus'>
              <Button className='video_img'>
                <img src="/images/videobg.png" alt=""/>
              </Button>
              <IconWrap>
                <IconButton><TwitterIcon/></IconButton>
                <IconButton><MirrorIcon/></IconButton>
                <IconButton><DiscordIcon/></IconButton>
              </IconWrap>
            </div> */}
          </div>
        </MainWrapper>
        <FooterWrapper>
          <Card className="cardItem">
            <div className="detail">
              <h4>We build tools helping everyone mint interest-bearing assets on the blockchain.</h4>
              <p>Hashpower, value-captured tokens, stocks, bonds, and more.</p>
            </div>
          </Card>
          <Card className="cardItem">
            <div className="detail">
              <h4>We help mining operators cash out without having to sell rigs.</h4>
              <p>Empowered investors can trade their interest-bearing assets into cryptos.</p>
            </div>
          </Card>
          <Card className="cardItem">
            <div className="detail">
              <h4>We enable investors to get access to all asset classes with one dApp.</h4>
              <p>Passively & actively managed, with achievable risk-neutral.</p>
            </div>
          </Card>
        </FooterWrapper>
      </Content>
    </HomeWrap>
  )
}

export default HomePage
