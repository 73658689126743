import React from 'react'
import Collateral from '../../../../../assets/image/Collateral.png'

const Icon = (props) => {
  return (
    <img src={Collateral} {...props} alt="" />
  )
}

export default Icon
