// src/routes.tsx
import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import PageLoader from './components/PageLoader'

// Lazy loaded components
const MyPage = lazy(() => import('views/MyPage'))
const Collateral = lazy(() => import('views/Collateral'))
const CollateralManage = lazy(() => import('views/Collateral/ManagerPage'))
const ShortNeutralize = lazy(() => import('views/ShortNeutralize'))
const BuyToken = lazy(() => import('views/BuyToken'))
const Faucet = lazy(() => import('views/Faucet'))
const Liquidation = lazy(() => import('views/Liquidation'))
const LiquidationDetail = lazy(() => import('views/Liquidation/detail'))
const ClaimedHistory = lazy(() => import('views/History/Claimed'))
const Obligation = lazy(() => import('views/History/Obligation'))
const Dashboard = lazy(() => import('views/Dashboard'))
const Hedging = lazy(() => import('views/Hedging'))
const NotFound = lazy(() => import('./views/NotFound'))

const Routes: React.FC = () => {
  return (
    <SuspenseWithChunkError fallback={<PageLoader />}>
      <Switch>
        <Route path="/mypage" exact component={MyPage} />
        <Route path="/collateral" exact component={Collateral} />
        <Route path="/collateral/manage" exact component={CollateralManage} />
        <Route path="/shortneutralize" exact component={ShortNeutralize} />
        <Route path="/hedging" exact component={Hedging} />
        <Route path="/faucet" exact component={Faucet} />
        <Route path="/liquidation" exact component={Liquidation} />
        <Route path="/liquidation/detail" exact component={LiquidationDetail} />
        <Route path="/buytoken" component={BuyToken} />
        <Route path="/history/claimed/:id(0|1|2)" component={ClaimedHistory} />
        <Route path="/history/obligation" component={Obligation} />
        <Route path="/epochStat" component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </SuspenseWithChunkError>
  )
}

export default Routes
