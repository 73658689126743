import {createAction} from "@reduxjs/toolkit";
import {CollateralProps} from "./type";

export const fetchCollateralList = createAction<{ collateralList:CollateralProps[] }>('CollateralManage/fetchCollateralList')
export const updateCollateralList = createAction<{ collateralList:CollateralProps[] }>('CollateralManage/updateCollateralList')
export const toggleStatus = createAction<{expanded:string }>('CollateralManage/toggleStatus');
export const updateCurrentManagerAddress = createAction<{currentManagerAddress:string }>('CollateralManage/updataCurrentManagerAddress');
export const updateRatio = createAction<{ratio:number}>('CollateralManage/updateRatio');
export const updateGuarantee = createAction<{guarantee:number}>('CollateralManage/updateGuarantee');
export const updateCurrentCPT = createAction<{data: any}>('CollateralManage/updateCurrentCPT');
export const updateFBTC20ApproveStatus = createAction<{status: boolean}>('CollateralManage/updateFBTC20ApproveStatus');
