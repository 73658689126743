import React from 'react'
import Svg from 'components/uikit/components/Svg/Svg'

function Wallet(props) {
  return (
    <Svg viewBox="0 0 17 14" {...props}>
      <path
        d="M14.7341 0.333984H2.56436C1.33413 0.333984 0.333252 1.33505 0.333252 2.56509V11.4895C0.333252 12.7196 1.33413 13.7207 2.56436 13.7207H14.7341C15.9643 13.7207 16.9652 12.7196 16.9652 11.4895V2.56509C16.9652 1.33505 15.9643 0.333984 14.7341 0.333984ZM15.7482 8.44712H11.286C10.5032 8.44712 9.86617 7.81011 9.86617 7.02733C9.86617 6.24455 10.5032 5.60753 11.286 5.60753H15.7482V8.44712ZM15.7482 4.39056H11.286C9.83209 4.39056 8.6492 5.57347 8.6492 7.02733C8.6492 8.48118 9.83211 9.66409 11.286 9.66409H15.7482V11.4895C15.7482 12.0489 15.2932 12.5037 14.7341 12.5037H2.56436C2.0052 12.5037 1.55022 12.0489 1.55022 11.4895V2.56509C1.55022 2.00572 2.0052 1.55095 2.56436 1.55095H14.7341C15.2932 1.55095 15.7482 2.00572 15.7482 2.56509V4.39056ZM10.6775 7.02733C10.6775 7.47541 11.0407 7.83864 11.4888 7.83864C11.9369 7.83864 12.3001 7.47541 12.3001 7.02733C12.3001 6.57925 11.9369 6.21602 11.4888 6.21602C11.0407 6.21602 10.6775 6.57925 10.6775 7.02733Z"
        fill="#2AA1FF"
      />
    </Svg>
  )
}

export default Wallet
