import React, { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from 'web3-react-core'
import { BigNumber } from '@ethersproject/bignumber'
import { getContract, getProviderAndSigner, getSigner, isSupportedChainId } from 'utils/web3React'
import contracts from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import useCurrentCpt from 'hooks/useCurrentCpt'
import { INFURA_NETWORK_URLS, SupportedChainId } from 'config/constants/chains'
import ERC20ABI from 'config/abi/erc20.json'
import { ethers } from 'ethers'
import Multicall from 'utils/multicall'
import { findPath, Pool, pools } from 'config/constants/pool'
import useRefesh from 'hooks/useRefresh'

const SystemParamsContext = React.createContext<{
  [key: string]: any
  tokenPools: Array<{ toBalance: BigNumber; fromBalance: BigNumber } & Pool>
}>({
  tokenPools: [],
})
const erc20Interface = new ethers.utils.Interface(ERC20ABI)
const {
  blockUpdater,
  manager,
  EfficiencyMatrix: matrix,
  helper,
  actionMintSell,
  mintSellHelper,
  instrument,
  obsever,
  uniswapV2Multicall: uniswapV3Multicall,
} = contracts

function SystemParamsProvider({ children }) {
  const { library, account, chainId, active } = useWeb3React()
  const [signer, setSigner] = useState(null)
  const [accountContract, setAccountContract] = useState(null)
  const [blockUpdaterContract, setBlockUpdaterContract] = useState(null)
  const [oracleContract, setOracleContract] = useState(null)
  const [actionMintSellContract, setActionMintSellContract] = useState(null)
  const [matrixContract, setMatrixContract] = useState(null)
  const [instrumentContract, setInstrumentContract] = useState(null)
  const [instrumentContract2, setInstrumentContract2] = useState(null)
  const [observerContract, setObserverContract] = useState(null)
  const [mintSellHelpContract, setMintSellHelpContract] = useState(null)
  const [helperContract, setHelperContract] = useState(null)
  const [params,] = useState({})

  const { fast } = useRefesh()

  const [tokenPools, setTokenPools] = useState<
    Array<
      {
        toBalance: BigNumber
        fromBalance: BigNumber
      } & Pool
    >
  >([])

  const { token } = useCurrentCpt()
  const getWBTC = useCallback(() => null, [])
  const _getPairBalance = useCallback(
    async (multiCall: Multicall) => {
      const calls = []
      for (const pool of pools[chainId as SupportedChainId]) {
        const { tokenAddressPath } = findPath({
          chainId: chainId as SupportedChainId,
          from: pool.from,
          to: pool.to,
        })
        calls.push(
          ...tokenAddressPath.map((address) => ({
            target: address,
            callData: erc20Interface.encodeFunctionData('balanceOf', [pool?.address]),
            functionSignature: 'balanceOf',
          })),
        )
        tokenAddressPath.forEach((address) => {
          multiCall.addAbi(address, ERC20ABI)
        })
      }
      if (calls.length) {
        const { returnData } = await multiCall.aggregate(calls)
        returnData.balanceOf = returnData.balanceOf.flat(Number.POSITIVE_INFINITY)
        const newPools = pools[chainId as SupportedChainId].map((pool, index) => {
          const fromBalance = returnData.balanceOf[index * 2]
          const toBalance = returnData.balanceOf[index * 2 + 1]
          return {
            ...pool,
            fromBalance,
            toBalance,
          }
        })
        setTokenPools((state) => {
          if (JSON.stringify(state) === JSON.stringify(newPools)) {
            return state
          }
          return newPools
        })
      }
    },
    [chainId],
  )
  const getTokensValues = useCallback(async () => {
    if (chainId && isSupportedChainId(chainId)) {
      const multicall = new Multicall(chainId, uniswapV3Multicall.address, uniswapV3Multicall.abi)

      _getPairBalance(multicall)
    }
  }, [_getPairBalance, chainId])

  useEffect(() => {
    getTokensValues()
  }, [getTokensValues, fast])

  useEffect(() => {
    if (active && account && chainId && isSupportedChainId(chainId)) {
      const Signer = getSigner(library, account)
      const { signer: customRPCSigner } = getProviderAndSigner(INFURA_NETWORK_URLS[chainId], account, chainId)
      const accContract = getContract(Signer, manager.accountRegister.abi, manager.accountRegister.address[chainId])
      const _matrixContract = getContract(Signer, matrix.abi, matrix.address[chainId])
      const _helper = getContract(customRPCSigner, helper.abi, helper.address[chainId])
      const orContract = getContract(Signer, manager.oracleRegister.abi, manager.oracleRegister.address[chainId])
      const _actionMintSell = getContract(Signer, actionMintSell.abi, actionMintSell.address[chainId])
      const _mintSellHelpContract = getContract(Signer, mintSellHelper.abi, mintSellHelper.address[chainId])
      const _blockUpdaterContract = getContract(Signer, blockUpdater.abi, blockUpdater.address[chainId])
      const _instrumentContract = getContract(Signer, instrument.abi, instrument.address[chainId])
      const _instrumentContract2 = getContract(Signer, instrument.abi, instrument.address2[chainId])
      const _observerContract = getContract(Signer, obsever.abi, obsever.address[chainId])

      setBlockUpdaterContract(_blockUpdaterContract)
      setSigner(Signer)
      setOracleContract(orContract)
      setActionMintSellContract(_actionMintSell)
      setMintSellHelpContract(_mintSellHelpContract)
      setAccountContract(accContract)
      setMatrixContract(_matrixContract)
      setHelperContract(_helper)
      setInstrumentContract(_instrumentContract)
      setInstrumentContract2(_instrumentContract2)
      setInstrumentContract(_instrumentContract)
      setObserverContract(_observerContract)
    }
  }, [account, active, chainId, library])
  useEffect(() => {
    if (active && account && chainId && isSupportedChainId(chainId)) {
      const Signer = getSigner(library, account)
      if (tokens[token] && tokens[token].blockUpdater) {
        const _blockUpdaterContract = getContract(Signer, blockUpdater.abi, tokens[token].blockUpdater.address[chainId])
        setBlockUpdaterContract(_blockUpdaterContract)
      }
    }
  }, [account, active, chainId, library, token])
  return (
    <SystemParamsContext.Provider
      value={{
        ...params,
        accountContract,
        matrixContract,
        helperContract,
        actionMintSellContract,
        mintSellHelpContract,
        oracleContract,
        blockUpdaterContract,
        instrumentContract,
        instrumentContract2,
        observerContract,
        signer,
        getWBTC,
        tokenPools,
      }}
    >
      {children}
    </SystemParamsContext.Provider>
  )
}

export { SystemParamsProvider, SystemParamsContext }
