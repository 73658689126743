import React from 'react'
import { ChainTxUrl } from "config/constants/chains"
import { AlertButton, FlexWrap } from "./CommonComponent"

export const TransactionSubmittedInfo: React.FC<{ txHash: string, content: string }> = ({ txHash, content }) => (
  <div>
    <p style={{ marginBottom: 24 }}>{content}</p>
    <FlexWrap style={{ flexDirection: 'row-reverse' }}>
      <AlertButton
        onClick={() => {
          window.open(`${ChainTxUrl}/${txHash}`)
        }}
      >
        View on Explorer
      </AlertButton>
    </FlexWrap>
  </div>
)

export const TransactionSuccessInfo: React.FC<{ txHash: string, content: string }> = ({ txHash, content }) => (
  <div>
    <p style={{ marginBottom: 24 }}>{content}</p>
    <FlexWrap style={{ flexDirection: 'row-reverse' }}>
      <AlertButton
        onClick={() => {
          window.open(`${ChainTxUrl}/${txHash}`)
        }}
      >
        View on Explorer
      </AlertButton>
    </FlexWrap>
  </div>
)