import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { WalletLinkConnector } from 'web3-react-walletlink-connector'
import { Web3Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId, INFURA_NETWORK_URLS } from '../config/constants/chains'

const injected = new InjectedConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: INFURA_NETWORK_URLS,
  qrcode: true,
})

const bscConnector = new BscConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

export const walletlink = new WalletLinkConnector({
  url: INFURA_NETWORK_URLS[SupportedChainId.BLAST_SEPPLIA],
  appName: 'MappingFunk',
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})
export const connectorsByName = {
  injected,
  walletconnect,
  bscConnector,
  walletlink,
}

// const provider = new providers.Web3Provider()

export const getContract = (signer = undefined, abi, address, provider?) => {
  if (signer && abi) {
    return new Contract(address, abi, signer)
  }
  if (!signer) {
    return new Contract(address, abi, provider)
  }
  return false
}
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export const getLibrary = (provider): Web3Provider => {
  return new Web3Provider(provider)
}

export const isSupportedChainId = (chainId) => {
  return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
}

const cache: { [key: string]: { provider: ethers.providers.StaticJsonRpcProvider; signer: ethers.Signer } } = {};

export function getProviderAndSigner(rpcUrl: string, account: string, chainId: SupportedChainId) {
  const cacheKey = `${rpcUrl}-${account}-${chainId}`;
  
  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  const provider = new ethers.providers.StaticJsonRpcProvider(rpcUrl, chainId);
  const signer = provider.getSigner(account);
  const result = { provider, signer };

  cache[cacheKey] = result;
  
  return result;
}