import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import { load, save } from 'redux-localstorage-simple'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { BigNumber } from 'ethers'
import { CHAIN_IDS_TO_NAMES } from 'config/constants/chains'
import header from './Header/reducer'
import collateralManage from './CollateralManage/reducer'
import liquidation from './Liquidation/reducer'
import MintToken, { initialState as MintInitialState } from './MintToken/slice'
import short from './Short/shortSlice'
import calculatorSlice from './calculatorSlice'
import epochSlice from './epochSlice'

const PERSISTED_KEYS: string[] = ['collateralManage', 'holdings', 'MintToken', 'epochSlice']

// const
const mergeState = (cacheState, key: 'collateralManage' | 'holdings' | 'MintToken') => {
  if(cacheState[key]) {
    const needMerge = {
      ...cacheState[key],
    };
    if (key === 'MintToken') {
      for (const chainId of Object.keys(CHAIN_IDS_TO_NAMES)) {
        for (const mintToken in MintInitialState.positions[chainId]) {
          if (Object.prototype.hasOwnProperty.call(MintInitialState.positions[chainId], mintToken)) {
            if (needMerge.positions[chainId] && !needMerge.positions[chainId][mintToken]) {
              console.log('MintInitialState.positions[chainId][mintToken]', MintInitialState.positions[chainId][mintToken])
              needMerge.positions[chainId]= {
                ...needMerge.positions[chainId],
                [mintToken]: MintInitialState.positions[chainId][mintToken],  
              }
            }
          }
        }
      }
    }
    return {
      ...cacheState,
      [key]: needMerge,
    }
  }
  return cacheState
}

const convertStringsToBigNumbers = (state) => {

  const reviver = (key, value) => {
    if (value && value.type && value.type === 'BigNumber') {
      return BigNumber.from(value)
    }
    return value
  }
  const nextState = mergeState(state, 'MintToken')
  const newState = JSON.parse(JSON.stringify(nextState), reviver)
  return newState
}

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    header,
    collateralManage,
    liquidation,
    short,
    MintToken,
    calculatorSlice,
    epochSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false }).concat(
      save({ states: PERSISTED_KEYS, debounce: 1000 }),
    ),
  preloadedState: convertStringsToBigNumbers(
    load({
      states: PERSISTED_KEYS,
      disableWarnings: true,
    }),
  ),
})

setupListeners(store.dispatch)

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export default store
