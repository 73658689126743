import React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Card from 'components/Card'
import AddSharpIcon from '@mui/icons-material/AddSharp'
import { Button as ButtonStyled, Button } from 'components/uikit'

export const Wrapper = styled(Card)`
  margin-bottom: 20px;
  padding-bottom: 0 !important;
`

export const Title = styled('h3')`
  font-family: PingFangSC-Semibold, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 22px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 10px;
`

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: '.2px solid #a5a5a5',
  background: 'unset',
  boxShadow: 'none',
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<AddSharpIcon style={{ color: '#2AA1FF' }} />} {...props} />
))(() => ({
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '20px 0',
    p: {
      color: '#000',
      fontSize: '1rem',
      fontFamily: 'PingFangSC-Semibold, sans-serif',
      fontWeight: 600,
      lineHeight: '22px',
    },
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0 0 30px 0',
  transition: '.3s',
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export const SelectWrap = styled('div')`
  display: flex;
  justify-content: space-between;
`
export const Approve = styled(Button)`
  display: block !important;
  background: linear-gradient(90deg, #61c2f7 0%, #7ae9f0 100%);
  border-radius: 20px !important;
  color: #000;
  width: 30% !important;
  margin: 0 auto !important;
  box-shadow: none !important;
`
export const ActionBtn = styled(Button)`
  display: block !important;
  background: linear-gradient(90deg, #61c2f7 0%, #7ae9f0 100%);
  border-radius: 20px !important;
  color: #000;
  //width: 30% !important;
  margin: 0 auto !important;
  box-shadow: none !important;
`
export const DataWrap = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  div {
    display: flex;
    margin-bottom: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);

    p + p {
      margin-left: 20px;
    }
  }

  .days {
    margin: 0;
  }
`

export const GroupWrap = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 25px);

  p {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;

    span {
      font-size: 12px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`

export const IconWrap = styled('strong')`
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 12px;
  background: #c4c4c4;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  flex: none;
`
export const TipWrap = styled('div')`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  background: #f1f1f1;
  border-radius: 8px;
  padding: 14px 80px 13px 21px;
  margin-bottom: 20px;

  strong {
    margin-right: 16px;
  }
  * {
    font-family: PingFangSC-Regular, sans-serif;
  }
`

export const TextIcon = styled('span')`
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
`

export const FlexWrap = styled('div')`
  display: flex;
`
export const icon = (Child, name) => {
  return function Comp(props) {
    return (
      <>
        <Child {...props}>{name}</Child>
      </>
    )
  }
}

export const AlertButton = styled(ButtonStyled)`
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 14px !important;
  background: linear-gradient(270deg, #7ae9f1 0%, #61c3f7 100%) !important;
  border-radius: 14px !important;
  font-size: 14px !important;
  height: 32px !important;
  line-height: 32px !important;
  color: #000 !important;
  font-weight: normal !important;
`

export const BTitle = styled('h1')`
  font-family: 'Heebo Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;
  margin-bottom: 14px;
  color: #000000;
`

export const ScrollWrapper = styled('div')`
  height: calc(100vh - 189px);
  padding-bottom: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`