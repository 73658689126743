export interface Address {
  97?: string
  56: string
}

export interface Props {
  token?: string
  symbol?: string
  address?: any
  balance?: number | null
  networkName?: string
  chainId?: number
}

export interface PoolProps extends Props {
  rightOptionList?: PoolProps[]
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}



export enum tokensSymbol {
  WBTC = 'WBTC',
  USDT = 'USDT',
  FBTC20 = 'F(BTC,20)',
  FBTC25 = 'F(BTC,25)',
}


export enum kovanAddresses {
  AssetIssuer = '0x291f65ded73c1390aecbad3e9a938951aefdbeb1',
  uniV2Router = '0x7a250d5630b4cf539739df2c5dacb4c659f2488d',
  uniV2Factory = '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
  USDT = '0xa91ae25794d4caa73c8a521ffdbbfe2c1c0da172',
  WBTC = '0x7e11aac35bd6159fb4358879e8fd8cbb92a22268',
  USDTWBTCPair = '0xb99a29342c0083a5f3dd5836db7869dcfc11da6a',
  UpdaterDefaultTestBTC = '0x635f6bcd0e04e395e81a292f4346bec8f2aef7d9',
  FBTC20 = '0x306a03d37eD9Cd537eC5e98C44D93C406fceC236',
  FBTC25 = '0xdc5461ad03596f2D56C15967258c25D23319a89b',
  OracleHub = '0xdd600544f05ab719dbafaa42809e3880cf3e206c',
  Matrix = '0xa0e538e7eb97053460c811ecd8470351f963cb20',
  FBTC20Manager = '0xba8e1fd18744aa9ecb8639b08c9f03672d06794d',
}

