// src/index.tsx
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter, Route, Switch } from 'react-router-dom'
import HomePage from 'views/Home'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import * as ethers from 'ethers'
import App from './App'
import Providers from './Providers'
import { ResetCSS } from './components/uikit'
import GlobalStyle from './style/Global'
import ToastListener from './components/ToastListener'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const AppWrapper = () => {

  // @ts-ignore 
  window.ethers = ethers
  return (
    <Providers>
      <App />
      <ToastListener />
    </Providers>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <ResetCSS />
      <GlobalStyle />
      <HashRouter>
        <Switch>
          <Route path="/:router" component={AppWrapper} />
          <Route path="/" exact component={HomePage} />
        </Switch>
      </HashRouter>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
