import { BigNumber } from 'ethers'
import { chainConfigs, SupportedChainId, SupportedTokens, valueToKeyMap } from './tokenConfig'

/**
 * Represents a pool.
 * @typedef {Object} Pool
 * @property {SupportedTokens} from - The token to exchange from.
 * @property {SupportedTokens} to - The token to exchange to.
 * @property {string} [address] - The optional address of the pool.
 */
export interface Pool {
  [x: string]: any
  from: SupportedTokens
  to: SupportedTokens
  address?: string
}

/**
 * Represents a pool with balance information.
 * @typedef {Object} PoolWithBalance
 * @property {SupportedTokens} from - The token to exchange from.
 * @property {SupportedTokens} to - The token to exchange to.
 * @property {BigNumber} fromBalance - The balance of the 'from' token.
 * @property {BigNumber} toBalance - The balance of the 'to' token.
 * @property {string} [address] - The optional address of the pool.
 */
export interface PoolWithBalance extends Pool {
  fromBalance: BigNumber
  toBalance: BigNumber
}

// Define the pools
export const pools: Record<SupportedChainId, Pool[]> = {
  [SupportedChainId.BLAST_SEPPLIA]: [
    { from: SupportedTokens.USDT, to: SupportedTokens.WBTC, address: '0xF74A91BF19CE41898Fa825ea8Bb7759629975Fe1' }, // Example rate
    { from: SupportedTokens.FBTC20, to: SupportedTokens.WBTC, address: '0xAaC86476a1f2a15E1Aac5270b928d26eAF0ceEac' }, // Example rate
    { from: SupportedTokens.FBTC25, to: SupportedTokens.WBTC, address: '0xe39dbd9edfb7eb6302efe4afe1b0d3bc9c6e0509' }, // Example rate
    { from: SupportedTokens.FETH, to: SupportedTokens.WETH, address: '0x29F019c39BfcEE136D6931042ff6E69C8811D3b0' }, // Example rate
    { from: SupportedTokens.USDT, to: SupportedTokens.WETH, address: '0xb59b65c2662442c6Df04Df0d49Bc982e281519E4' }, // Example rate
  ],
}

// Define the pool indexes
export const poolIndexes: Record<SupportedChainId, { [key: string]: Pool }> = Object.keys(pools).reduce(
  (indexes, chainId) => {
    const poolList = pools[chainId]
    return {
      ...indexes,
      [chainId]: poolList.reduce((index, pool) => {
        return {
          ...index,
          [`${pool.from}_${pool.to}`]: pool,
          [`${pool.to}_${pool.from}`]: pool,
        }
      }, {} as { [key: string]: Pool }),
    }
  },
  {} as Record<SupportedChainId, { [key: string]: Pool }>,
)

/**
 * Get the pool address.
 * @param {SupportedChainId} chainId - The ID of the supported chain.
 * @param {SupportedTokens} from - The token to exchange from.
 * @param {SupportedTokens} to - The token to exchange to.
 * @returns {string|undefined} The pool address if found, otherwise undefined.
 */
export const getPoolAddress = (
  chainId: SupportedChainId,
  from: SupportedTokens,
  to: SupportedTokens,
): string | undefined => {
  const key = `${from}_${to}`
  const pool = poolIndexes[chainId][key]
  return pool ? pool.address : undefined
}

/**
 * Find the exchange path.
 * 
 * @param {Object} param - The parameters object.
 * @param {SupportedChainId} param.chainId - The ID of the supported chain.
 * @param {SupportedTokens} param.from - The token to exchange from.
 * @param {SupportedTokens} param.to - The token to exchange to.
 * @param {T[]} [param.poolInfo] - Optional pool information.
 * @returns {Object|null} The pool path information if found, otherwise null.
 */
export function findPath<T extends Pool>({
  chainId,
  from,
  to,
  poolInfo,
}: {
  chainId: SupportedChainId
  from: SupportedTokens
  to: SupportedTokens
  poolInfo?: T[]
}): { poolPath: T[]; tokenAddressPath: string[]; pathName: string } | null {
  if (!poolInfo && !pools[chainId]) {
    return null
  }
  const visited = new Set<string>()
  const queue: { token: string; path: any[] }[] = [{ token: from, path: [] }]

  while (queue.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { token, path } = queue.shift()!

    if (token === to) {
      return {
        poolPath: path as T[],
        tokenAddressPath: Array.from(
          new Set(
            path
              .map((pool) => [
                chainConfigs[chainId].tokens[valueToKeyMap[pool.from]].address,
                chainConfigs[chainId].tokens[valueToKeyMap[pool.to]].address,
              ])
              .flat(),
          ),
        ),
        pathName: Array.from(new Set(path.map((pool) => [pool.from, pool.to]).flat())).join('-'),
      }
    }
    for (const pool of poolInfo || pools[chainId]) {
      if (pool.from === token && !visited.has(pool.to)) {
        visited.add(pool.to)
        queue.push({ token: pool.to, path: [...path, pool] })
      } else if (pool.to === token && !visited.has(pool.from)) {
        visited.add(pool.from)
        if (Object.prototype.hasOwnProperty.call(pool, 'fromBalance')) {
          queue.push({
            token: pool.from,
            path: [
              ...path,
              { fromBalance: pool?.toBalance, toBalance: pool?.fromBalance, from: pool.to, to: pool.from },
            ],
          })
        } else {
          queue.push({ token: pool.from, path: [...path, { from: pool.to, to: pool.from } as T] })
        }
      }
    }
  }

  return null // No path found
}

/**
 * Constant product market maker function.
 * @param {BigNumber} amountIn - The input amount.
 * @param {BigNumber} reserveIn - The input reserve.
 * @param {BigNumber} reserveOut - The output reserve.
 * @param {number} [slippage=3] - The slippage percentage.
 * @returns {BigNumber} The output amount.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAmountOut(amountIn: BigNumber, reserveIn: BigNumber, reserveOut: BigNumber, slippage = 3): BigNumber {
  const amountInWithFee = amountIn.mul(997).div(1000) // Considering 0.3% slippage
  const numerator = amountInWithFee.mul(reserveOut)
  const denominator = reserveIn.add(amountInWithFee)
  return numerator.div(denominator)
}

/**
 * Get swap quote prediction.
 * @param {SupportedChainId} chainId - The chain ID.
 * @param {SupportedTokens} fromToken - The initial token.
 * @param {SupportedTokens} toToken - The target token.
 * @param {BigNumber} amountIn - The input amount.
 * @param {PoolWithBalance[]} [poolInfo] - Custom pool information (optional).
 * @returns {BigNumber|null} The output amount or null if no path found.
 */
export function getSwapQuote(
  chainId: SupportedChainId,
  fromToken: SupportedTokens,
  toToken: SupportedTokens,
  amountIn: BigNumber,
  poolInfo?: PoolWithBalance[],
): BigNumber | null {
  const pathResult = findPath<PoolWithBalance>({ chainId, from: fromToken, to: toToken, poolInfo })
  if (!pathResult) {
    return null
  }

  let amountOut = amountIn
  for (const pool of pathResult.poolPath) {
    const poolDetails = (poolInfo || pools[chainId]).find(
      (p) => (p.from === pool.from && p.to === pool.to) || (p.from === pool.to && p.to === pool.from),
    ) as PoolWithBalance
    if (!poolDetails) {
      return null
    }

    const fromBalance = poolDetails.from === pool.from ? poolDetails.fromBalance : poolDetails.toBalance
    const toBalance = poolDetails.from === pool.from ? poolDetails.toBalance : poolDetails.fromBalance
    amountOut = getAmountOut(amountOut, fromBalance, toBalance)
  }

  return amountOut
}
