import { useCallback } from 'react'
import { useWeb3React } from 'web3-react-core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey } from 'components/uikit'
import { connectorsByName } from 'utils/web3React'
import useToast from 'hooks/useToast'
import { SupportedChainId } from 'config/constants/chains'
import switchToNetwork from 'utils/switchToNetwork'

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const { toastError } = useToast()
  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]

    if (connector) {
      activate(connector, async (error: Error) => {
        window.localStorage.removeItem(connectorLocalStorageKey)

        if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
          toastError('Provider Error', 'No provider was found')
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = null
          }
          toastError('Authorization Error', 'Please authorize to access your account')
        } else {
          // const errorInfo = error.message.split('.').map((it, index) => {
          //   const text = it.replace('ids', 'id').replace('are', '')
          //   const text2 = text.replace('chain id', 'ChainID')
          //   return `${index === 1 ? `${text2.replace(':', ':\n')}. Blast Test Network` : text2}`
          // })

          // const msg = errorInfo.join('.').replace('.', '.\n')

          switchToNetwork({ connector, chainId: SupportedChainId.BLAST_SEPPLIA })
            .then(() => {
              // console.log('connectconnectconnectconnectconnect')
            })
            .catch((errors) => {
              console.error('Failed to switch networks', errors)
            })

          // toastError(msg)
        }
      })
    } else {
      toastError("Can't find connector", 'The connector config is wrong')
    }
  }, [activate, toastError])

  const logout = useCallback(() => {
    deactivate()
  }, [deactivate])

  return { login, logout }
}

export default useAuth
