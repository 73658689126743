import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 14"  fill="none" {...props}>
        <circle cx="7" cy="7" r="5.5" stroke="black"/>
        <path d="M8.77715 7.30566H5.22399C5.04896 7.30566 4.94394 7.42888 5.03146 7.52366C5.16273 7.67531 6.72927 9.44771 6.80803 9.53301C6.9043 9.64675 7.11434 9.62779 7.20186 9.53301C7.26312 9.46667 8.87342 7.62792 8.96968 7.52366C9.0572 7.4194 8.94343 7.31514 8.77715 7.30566Z" fill="black"/>
        <path d="M5.22285 6.30566L8.77601 6.30566C8.95104 6.30566 9.05606 6.18245 8.96854 6.08767C8.83727 5.93602 7.27073 4.16362 7.19197 4.07832C7.0957 3.96458 6.88566 3.98354 6.79814 4.07832C6.73688 4.14466 5.12658 5.98341 5.03032 6.08767C4.9428 6.19193 5.05657 6.29619 5.22285 6.30566Z" fill="black"/>
    </Svg>
  )
}

export default Icon
