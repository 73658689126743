/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from 'web3-react-core'
import { getContract, getSigner, isSupportedChainId } from 'utils/web3React'
import tokens from 'config/constants/tokens'
import ERC20 from 'config/abi/erc20.json'
import { ToastTitle } from 'config/constants/contants'
import { TokenUtils, toSmallUnits } from 'utils/transformHelper'
import Multicall from 'utils/multicall'
import { chainConfigs, commonTokens, SupportedChainId } from 'config/constants/tokenConfig'
import { ethers, utils } from 'ethers'
import { sortTokens } from 'config/constants/mintToken'
import { TransactionSubmittedInfo } from 'components/TransactionInfo'
import useToast from './useToast'
import contracts from '../config/constants/contracts'

const Erc20Interface = new ethers.utils.Interface(ERC20)

type TransactionSubmittedInfo = any;


export function useManagerList() {
  const [managerList, setManagerList] = useState([])
  const { active, account, library, chainId } = useWeb3React()
  const generateData = useCallback(() => {
    const list = []
    if (active && account) {
      // find manager contracts
      Object.keys(contracts).forEach((it) => {
        if (it.includes('Manager')) {
          const signer = getSigner(library, account)
          const data = {
            ...contracts[it],
            signer,
            contract: getContract(signer, contracts[it].abi, contracts[it].address[chainId]),
          }
          list.push(data)
        }
      })
    }
    setManagerList(list)
  }, [account, active, chainId, library])
  useEffect(() => {
    generateData()
    return () => {
      setManagerList([])
    }
  }, [generateData, account])
  return managerList
}

export function useGetPosition(active, account, library) {
  const callback = useCallback(
    async (abi, managerAddress) => {
      if (active && library) {
        const signer = getSigner(library, account)
        const contract = getContract(signer, abi, managerAddress)
        if (contract) {
          return contract.positions(account)
        }
      }
      return null
    },
    [active, library, account],
  )

  return { getPosition: callback }
}

export function useCreatePosition(abi, managerAddress) {
  const { account, library, active } = useWeb3React()
  const [tx, setTx] = useState<any>()
  const callback = useCallback(async () => {
    if (active) {
      const signer = getSigner(library, account)
      const contract = getContract(signer, abi, managerAddress)
      if (contract) {
        setTx('waiting')
        return contract.createPosition()
      }
    }
    return null
  }, [active, library, account, abi, managerAddress])

  return { createPosition: callback, tx }
}

export function useGetTokenBalance(library, active, chainId) {
  const getBalance = useCallback(
    async (owner: string) => {
      if (active && chainId && isSupportedChainId(chainId)) {
        const multicall = new Multicall(chainId, contracts.uniswapV2Multicall.address, contracts.uniswapV2Multicall.abi)
        const calls = []
        const _tokens = Object.values(chainConfigs[chainId as SupportedChainId].tokens)
        for (const tokenInfo of _tokens) {
          calls.push({
            target: tokenInfo.address,
            callData: Erc20Interface.encodeFunctionData('balanceOf', [owner]),
            functionSignature: 'balanceOf',
          })
          multicall.addAbi(tokenInfo.address, ERC20)
        }
        try {
          const { returnData } = await multicall.aggregate(calls)

          return sortTokens<number>(
            _tokens.reduce((acc, tokenInfo, index) => {
              return {
                ...acc,
                // TODO now only keep 10 decimal
                [tokenInfo.symbol]: TokenUtils.formatDecimalString(utils.formatUnits(returnData.balanceOf[index][0], tokenInfo.decimals), tokenInfo.decimals),
              }
            }, {})
          )
        } catch (e) {
          return sortTokens<number>(
            _tokens.reduce((acc, tokenInfo) => {
              return {
                ...acc,
                [tokenInfo.symbol]: 0,
              }
            }, {})
          )
        }
      }
      return sortTokens<number>(
        Object.values(commonTokens).reduce((acc, tokenInfo) => {
          return {
            ...acc,
            [tokenInfo.symbol]: 0,
          }
        }, {})
      )
    },
    [active, chainId],
  )

  return {
    getBalance,
    getTokenBalance: useCallback(
      async (address) => {
        if (active && address && isSupportedChainId(chainId)) {
          const signer = getSigner(library, address)
          // const balance = {};
          const balance = {}
          for (const token in tokens) {
            // Object.prototype.hasOwnProperty.call(tokens, token)
            if (token) {
              balance[token] = 0
              const contract = getContract(signer, ERC20, tokens[token].address[chainId])
              if (contract) {
                try {
                  const _balance = await contract.balanceOf(address)
                  if (_balance) {
                    balance[token] = _balance / Number(toSmallUnits('1', tokens[token].decimals))
                  }
                } catch (e) {
                  console.log('address ---->', address, 'error ---->', e)
                  // alert('reload 111111');
                  // window.location.reload()
                }
              }
            }
          }
          return balance
        }
        return null
      },
      [active, chainId, library],
    ),
  }
}

/**
 * @desc transfer
 * */
export function useTransfer() {
  const { library } = useWeb3React()
  const { toastInfo } = useToast()
  return useCallback(
    async (owner, from, to, amount) => {
      if (library) {
        const signer = getSigner(library, owner)
        const contract = getContract(signer, ERC20, from)
        if (contract) {
          const tx = await contract.transfer(to, amount)
          toastInfo(ToastTitle.submited, TransactionSubmittedInfo({ txHash: tx.hash, content: 'Transfer submitted' }))
          return tx.wait()
        }
      }
      return null
    },
    [library, toastInfo],
  )
}
