import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MintTokenKey } from 'config/constants/mintToken'
import { BigNumber } from 'ethers'
import _, { set } from 'lodash'

export interface Epoch {
  epoch: number
  start: string | number
  factor: string | number
  acc: BigNumber
}

const epochSlice = createSlice({
  name: 'epochSlice',
  initialState: {
    epochList: {} as Record<MintTokenKey, Epoch[]>,
    epochCount: {} as Record<MintTokenKey, number>,
    epochCurent: {} as Record<MintTokenKey, number>,
  },
  reducers: {
    addEpochData: (state, action: PayloadAction<{ token: MintTokenKey; list: Epoch[] }>) => {
      if (!state.epochList[action.payload.token]) {
        state.epochList[action.payload.token] = action.payload.list
      } else {
        // 如果存在，合并新的数据并基于 epoch 去重
        state.epochList[action.payload.token] = _.unionBy(
          state.epochList[action.payload.token],
          action.payload.list,
          'epoch',
        )
      }
    },
    // 更新 epoch 数据
    updateEpochData: (state, action: PayloadAction<{ token: MintTokenKey; epoch: Epoch }>) => {
      if (!state.epochList[action.payload.token]) {
        state.epochList[action.payload.token] = [action.payload.epoch]
        return
      }
      const epochList = state.epochList[action.payload.token]
      const index = _.findIndex(epochList, { epoch: action.payload.epoch.epoch })
      if (index !== -1) {
        epochList[index] = { ...epochList[index], ...action.payload.epoch }
      } else {
        epochList.push(action.payload.epoch)
      }
    },
    removeEpochData: (state, action: PayloadAction<{ token: MintTokenKey; list: Epoch[] }>) => {
      state.epochList[action.payload.token] = state.epochList[action.payload.token].filter((item) => {
        return !action.payload.list.includes(item)
      })
    },
    clearEpochData: (state, action: PayloadAction<{ token: MintTokenKey }>) => {
      if (state.epochList[action.payload.token]) {
        state.epochList[action.payload.token] = []
      }
    },
    setEpochCount: (state, action: PayloadAction<{ token: MintTokenKey; count: number }>) => {
      state.epochCount[action.payload.token] = action.payload.count
    },
    setEpochCurrent: (state, action: PayloadAction<{ token: MintTokenKey; current: number }>) => {
      state.epochCurent[action.payload.token] = action.payload.current
    },
  },
})

export const {
  addEpochData,
  removeEpochData,
  clearEpochData,
  setEpochCount,
  setEpochCurrent,
} = epochSlice.actions

export default epochSlice.reducer
