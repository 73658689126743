import React from 'react'
import { ChainAddressUrl } from '../../../../config/constants/chains'
import Button from '../Button/Button'
import Text from '../Text/Text'
import LinkExternal from '../Link/LinkExternal'
import Flex from '../Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import { connectorLocalStorageKey } from './config'
import useCurrentBalance from '../../../../hooks/useCurrentBalance'

interface Props {
  account: string
  logout: () => void
  onDismiss?: () => void
  balance?: number
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => {
  const balance = useCurrentBalance()

  return (
    <Modal title="Your wallet" onDismiss={onDismiss}>
      <Text
        fontSize="20px"
        bold
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px' }}
      >
        {account}
      </Text>
      <Text
        fontSize="20px"
        bold
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px' }}
      >
        Current Balances: {balance || 0} ETH
      </Text>
      <Flex mb="32px">
        <LinkExternal small href={`${ChainAddressUrl}/${account}`} mr="16px">
          View on Blast Sepolia
        </LinkExternal>
        <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
      </Flex>
      <Flex justifyContent="center">
        <Button
          scale="sm"
          variant="secondary"
          onClick={() => {
            logout()
            window.localStorage.removeItem(connectorLocalStorageKey)
            onDismiss()
          }}
        >
          Logout
        </Button>
      </Flex>
    </Modal>
  )
}

export default AccountModal
