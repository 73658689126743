import { useContext } from 'react'
import {HashPowerTokenContext} from "contexts";

const useHashPowerToken = () => {
  const hashPowerToken = useContext(HashPowerTokenContext)

  if (hashPowerToken === undefined) {
    throw new Error('hashPowerToken context undefined')
  }

  return hashPowerToken
}

export default useHashPowerToken
