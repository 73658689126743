import {createReducer} from "@reduxjs/toolkit";

import {toggleWalletStatus,toggleNetworkStatus} from "./action";

export type statList = 'walletStatus' | 'networkStatus'
export interface HeaderState {
  walletStatus: boolean
  networkStatus: boolean
}

export const initialState: HeaderState = {
  walletStatus: false,
  networkStatus: false
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(toggleWalletStatus, (state) => {
      state.walletStatus = !state.walletStatus;
    })
    .addCase(toggleNetworkStatus, (state)=>{
      state.networkStatus = !state.networkStatus;
    })
)
