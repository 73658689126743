import React from 'react'
import myPage from "../../../../../assets/image/myPage.png";

const Icon = (props) => {
  return (
    <img src={myPage} {...props} alt="" />
  )
}

export default Icon
