import React from 'react'
import Svg from '../Svg'

export default function ArrowLine(props) {
  return (
    <Svg {...props} viewBox="0 0 18 8" fill="none" >
        <path
          d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM17.3536 4.35355C17.5488 4.15829 17.5488 3.84171 17.3536 3.64645L14.1716 0.464465C13.9763 0.269203 13.6597 0.269203 13.4645 0.464465C13.2692 0.659727 13.2692 0.97631 13.4645 1.17157L16.2929 4L13.4645 6.82843C13.2692 7.02369 13.2692 7.34027 13.4645 7.53553C13.6597 7.73079 13.9763 7.73079 14.1716 7.53553L17.3536 4.35355ZM1 4.5L17 4.5L17 3.5L1 3.5L1 4.5Z"
          fill="#2AA1FF"
        />
    </Svg>
  )
}
