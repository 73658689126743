import React, { useCallback, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { useWeb3React } from 'web3-react-core'
import Select from 'components/Select'
import { CHAIN_IDS_TO_NAMES, CHAIN_INFO, SupportedChainId } from 'config/constants/chains'
import useOnClickOutside from 'hooks/useOnClickOutside'
import switchToNetwork from 'utils/switchToNetwork'
import { useToggleNetworkStatus, useToggleStatus } from 'state/Header/hooks'

const SelectWrap = styled('div')`
  .MuiSelectUnstyled-root {
    padding: 2.5px;
    margin: 0 10px;
    font-size: 0.875rem;
  }
`

const options = [
  {
    networkName: CHAIN_IDS_TO_NAMES[SupportedChainId.BLAST_SEPPLIA],
    chainId: SupportedChainId.BLAST_SEPPLIA,
    icon: (
      <span
        style={{
          display: 'inline-block',
          marginRight: 12,
          width: 22,
          height: 22,
          borderRadius: '50%',
          background: '#009F42',
        }}
      />
    ),
  },
]
function NetworkSelector() {
  const { chainId, connector } = useWeb3React()
  const node = useRef<HTMLDivElement>()
  const open = useToggleStatus('networkStatus')
  const toggle = useToggleNetworkStatus()

  useOnClickOutside(node, open ? toggle : undefined)
  const info = chainId ? CHAIN_INFO[chainId] : undefined
  const handleChainSwitch = useCallback(
    (targetChain: number, skipToggle?: boolean) => {
      if (!connector) return
      switchToNetwork({ connector, chainId: targetChain })
        .then(() => {
          if (!skipToggle) {
            toggle()
          }
        })
        .catch((error) => {
          console.error('Failed to switch networks', error)
          if (!skipToggle) {
            toggle()
          }
        })
    },
    [connector, toggle],
  )

  if (options.findIndex((it) => it.chainId === chainId) === -1 && chainId) {
    options.push({
      networkName: CHAIN_IDS_TO_NAMES[chainId],
      chainId,
      icon: null,
    })
  }

  if (!chainId || !info || !connector) {
    return null
  }
  return (
    <SelectWrap>
      <Select
        selectValue={chainId}
        onChange={(val) => {
          handleChainSwitch(val)
        }}
        options={options}
        type="network"
      />
    </SelectWrap>
  )
}

export default NetworkSelector
