import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ethers, BigNumber, Contract } from 'ethers'
import {
  blastSepoliaMintTokens,
  SupportedMintTokens,
  MintTokenKey,
  mintTokenChainConfigs,
  sortTokens,
} from 'config/constants/mintToken'
import { INFURA_NETWORK_URLS, SupportedChainId } from 'config/constants/chains'
import { getContract, getProviderAndSigner } from 'utils/web3React'
import PositionABI from 'config/abi/Position.json'
import ERC20 from 'config/abi/erc20.json'
import { chainConfigs, tokenAddresKey, TokenConfig } from 'config/constants/tokenConfig'
import { toSmallUnits } from 'utils/transformHelper'
import { computedCR, computedPI, computedPIC, computedTC } from 'utils/computed'
import BlockUpdaterABI from 'config/abi/BlockUpdater.json'
import contracts from 'config/constants/contracts'
import Multicall from 'utils/multicall'
import { MintTokenState, Position, PositionStatus } from './type'

const { EfficiencyMatrix, uniswapV2Multicall } = contracts
const Erc20Interface = new ethers.utils.Interface(ERC20)
const NullAddress = '0x0000000000000000000000000000000000000000'

const initialPosition: Omit<Position, 'mintTokenInfo'> = {
  isEnable: false,
  positionAddress: NullAddress,
  balance: ethers.constants.Zero,
  collateralRatio: 0,
  prepaidInterestCoverage: 0,
  collateralTokenInfo: [],
  lastEpochReward: ethers.constants.Zero,
  positionStatus: {
    shortBalanceValue: ethers.constants.Zero,
    collateralValue: ethers.constants.Zero,
    obligation: ethers.constants.Zero,
    epochDebt: ethers.constants.Zero,
    estimatedObligationToday: ethers.constants.Zero,
    estimatedDailyDebt: ethers.constants.Zero,
    interestGuaranteed: ethers.constants.Zero,
  },
}

export const initialState: MintTokenState = {
  positions: {
    [SupportedChainId.BLAST_SEPPLIA]: {
      FBTC20: {
        ...initialPosition,
        mintTokenInfo: {
          ...blastSepoliaMintTokens.FBTC20,
        },
      },
      FBTC25: {
        ...initialPosition,
        mintTokenInfo: {
          ...blastSepoliaMintTokens.FBTC25,
        },
      },
      FETH: {
        ...initialPosition,
        mintTokenInfo: {
          ...blastSepoliaMintTokens.FETH,
        },
      },
    },
  },
  tokenPrice: {},
  allowedTokens: {},
  currentMintToken: SupportedMintTokens.FBTC20,
}
/**
 * @description Fetch single position info
 * @param {string} account
 * @param {SupportedChainId} chainId
 * @param {any} accountContract
 * @param {any} signer
 * @param {any} helperContract
 * @param {MintTokenKey} tokenKey
 * @return {Promise}
 * @example
 * dispatch(fetchSinglePositionInfo({
 *  "0x0000xxxxx",
 *  1,
 *  new Contract(xxx),
 *  signer,
 *  new Contract(xxx),
 *  "FBTC20",
 * }))
 * */
export const fetchSinglePositionInfo = createAsyncThunk(
  'MintToken/fetchSinglePositionInfo',
  async (
    {
      account,
      chainId,
      accountRegister,
      helperContract,
      tokenKey,
    }: {
      account: string
      chainId: SupportedChainId
      accountRegister: any
      signer?: any
      helperContract: any
      tokenKey: MintTokenKey
    },
    thunkAPI,
  ) => {
    try {
      const { signer } = getProviderAndSigner(
        INFURA_NETWORK_URLS[SupportedChainId.BLAST_SEPPLIA],
        account,
        SupportedChainId.BLAST_SEPPLIA,
      )
      const token = mintTokenChainConfigs[chainId].tokens[tokenKey]
      const position = await accountRegister.positions(account, token.address)
      // const posContract = getContract(signer, PositionABI, position)
      if (!Number(position)) {
        return {
          chainId,
          tokenKey,
          data: {
            positionStatus: {
              ...initialPosition.positionStatus,
            },
            balance: ethers.constants.Zero,
            positionAddress: position,
            isEnable: false,
            lastEpochReward: ethers.constants.Zero,
          },
        }
      }
      const posContract = new ethers.Contract(position, PositionABI, signer)
      const balance: BigNumber = await posContract.balance()
      const status = (await helperContract.getPositionStatus(position, balance)) as PositionStatus
      const blockUpdaterContract = new ethers.Contract(
        mintTokenChainConfigs[chainId].tokens[tokenKey].blockUpdater,
        BlockUpdaterABI,
        signer,
      )
      const lastEpochReward: BigNumber = await blockUpdaterContract.lastEpochReward()
      await thunkAPI.dispatch(
        fetchTokenPrice({
          chainId,
          mintToken: tokenKey,
          position,
        }),
      )
      return {
        tokenKey,
        chainId,
        data: {
          lastEpochReward,
          positionStatus: {
            shortBalanceValue:balance.isZero() ? ethers.constants.Zero: status.shortBalanceValue,
            collateralValue: status.collateralValue,
            obligation: status.obligation,
            epochDebt: status.epochDebt,
            estimatedObligationToday: status.estimatedObligationToday,
            estimatedDailyDebt: status.estimatedDailyDebt,
            interestGuaranteed: status.interestGuaranteed,
          } as PositionStatus,
          balance,
          positionAddress: position,
          isEnable: Number(position) > 0,
        },
      }
    } catch (e) {
      console.error('error in fetchSinglePositionInfo', e)
      return {}
    }
  },
)
export const fetchAllowedTokensBalance = createAsyncThunk(
  'MintToken/fetchAllowedTokensBalance',
  async ({ position, allowedTokens }: { position: string; allowedTokens: TokenConfig[]; signer?: any }) => {
    const balances = await Promise.all(
      allowedTokens.map(async (token) => {
        const { provider } = getProviderAndSigner(
          INFURA_NETWORK_URLS[SupportedChainId.BLAST_SEPPLIA],
          position,
          SupportedChainId.BLAST_SEPPLIA,
        )
        const erc20Contract = getContract(provider, ERC20, token.address)
        if (erc20Contract) {
          return erc20Contract.balanceOf(position)
        }
        return {
          target: token.address,
          callData: Erc20Interface.encodeFunctionData('balanceOf', [position]),
        }
      }),
    )
    console.log('balances', balances)
    return {
      position,
      balances,
      allowedTokens,
    }
  },
)

export const fetchTokenPrice = createAsyncThunk(
  'MintToken/fetchTokenPrice',
  async ({
    chainId,
    mintToken,
    position,
  }: {
    chainId: SupportedChainId
    mintToken: MintTokenKey
    matrixContract?: Contract
    position: string
  }) => {
    const tokenMap = tokenAddresKey(chainId)
    const { tokens } = chainConfigs[chainId]
    try {
      const { provider } = getProviderAndSigner(INFURA_NETWORK_URLS[chainId], position, SupportedChainId.BLAST_SEPPLIA)
      const matrixContract = new ethers.Contract(EfficiencyMatrix.address[chainId], EfficiencyMatrix.abi, provider)
      const allowedTokens: string[] = await matrixContract.queryAllowedAssets(
        mintTokenChainConfigs[chainId].tokens[mintToken].address,
      )
      const params = allowedTokens
        .filter((address) => Object.prototype.hasOwnProperty.call(tokenMap, address.toLowerCase()))
        .map((address) => tokenMap[address.toLowerCase()])

      const priceCall = {
        target: matrixContract.address,
        callData: matrixContract.interface.encodeFunctionData('queryValues', [
          params.map((token) => [token.address, toSmallUnits('1', token.decimals), true]),
          mintTokenChainConfigs[chainId].tokens[mintToken].settlementCurrency.address,
          position,
        ]),
        functionSignature: 'queryValues',
      }

      const balanceCalls = params.map((token) => ({
        target: token.address,
        callData: Erc20Interface.encodeFunctionData('balanceOf', [position]),
        functionSignature: 'balanceOf',
      }))

      const calls = [priceCall, ...balanceCalls]
      const multicall = new Multicall(chainId, uniswapV2Multicall.address, uniswapV2Multicall.abi)
      multicall.addAbi(EfficiencyMatrix.address[chainId], EfficiencyMatrix.abi)
      // multicall.addAbi()
      params.forEach((token) => {
        multicall.addAbi(token.address, ERC20)
      })

      const { returnData } = await multicall.aggregate<{
        queryValues: [BigNumber[]]
        balanceOf: [BigNumber][]
      }>(calls)

      const price = returnData.queryValues.flat(Number.POSITIVE_INFINITY)
      const balances = returnData.balanceOf.flat(Number.POSITIVE_INFINITY)
      const priceInfo = params.map((token, index) => ({
        token: token.symbol,
        price: price[index],
      }))
      return {
        mintToken,
        chainId,
        priceInfo,
        position,
        allowedTokens: {
          [position]: {
            tokens: sortTokens(
              params.reduce((acc, curr) => {
                return { ...acc, [curr.symbol]: curr }
              }, {}),
            ),
            balances: params.reduce((acc, curr, index) => {
              return { ...acc, [curr.symbol]: balances[index] }
            }, {}),
          },
        },
      }
    } catch (error) {
      console.error('fetchTokenPrice error :>> ', error)

      return {
        mintToken,
        chainId,
        position,
        priceInfo: Object.values(tokens).map((token) => ({
          token: token.symbol,
          price: ethers.constants.Zero,
        })),
        allowedTokens: {
          [position]: {
            tokens: {},
            balances: {},
          },
        },
      }
    }
  },
)

const mintTokenSlice = createSlice({
  name: 'MintToken',
  initialState,
  reducers: {
    updateCurrentMintToken(state, action: PayloadAction<SupportedMintTokens>) {
      state.currentMintToken = action.payload
    },
    updateCollateralRatio(
      state,
      action: PayloadAction<{ chainId: SupportedChainId; mintTokenKey: MintTokenKey; collateralRatio: number }>,
    ) {
      const { chainId, mintTokenKey, collateralRatio } = action.payload
      if (state.positions[chainId] && state.positions[chainId][mintTokenKey]) {
        state.positions[chainId][mintTokenKey].collateralRatio = collateralRatio
      }
    },
    updatePrepaidInterestCoverage(
      state,
      action: PayloadAction<{ chainId: SupportedChainId; mintTokenKey: MintTokenKey; prepaidInterestCoverage: number }>,
    ) {
      const { chainId, mintTokenKey, prepaidInterestCoverage } = action.payload
      if (state.positions[chainId] && state.positions[chainId][mintTokenKey]) {
        state.positions[chainId][mintTokenKey].prepaidInterestCoverage = prepaidInterestCoverage
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSinglePositionInfo.fulfilled, (state, action) => {
        const { tokenKey: mintTokenKey, data, chainId } = action.payload
        if (Object.keys(action.payload).length === 0) return
        if (state.positions[chainId] && state.positions[chainId][mintTokenKey]) {
          const prepaidInterest = computedPI({
            interestGuaranteed: Number(data.positionStatus.interestGuaranteed),
            obligation: Number(data.positionStatus.obligation),
            epochDebt: Number(data.positionStatus.epochDebt),
            estimatedObligationToday: Number(data.positionStatus.estimatedObligationToday),
          })
          const totalCollateralValue = computedTC({
            collateralValue: Number(data.positionStatus.collateralValue),
            prepaidInterest,
          })
          const prepaidInterestCoverage = computedPIC({
            prepaidInterest,
            estimatedDailyDebt: Number(data.positionStatus.estimatedDailyDebt),
          })
          const collateralRatio = computedCR({
            totalCollateralValue,
            shortBalanceValue: Number(data.positionStatus.shortBalanceValue),
          })

          state.positions[chainId][mintTokenKey] = {
            ...state.positions[chainId][mintTokenKey],
            ...data,
            collateralRatio,
            prepaidInterestCoverage,
          }
        }
      })
      .addCase(fetchTokenPrice.fulfilled, (state, action) => {
        const { priceInfo, position, allowedTokens } = action.payload
        if (position === NullAddress) return
        state.tokenPrice[position] =
          priceInfo.reduce((acc, curr) => {
            return { ...acc, [curr.token]: { price: curr.price, symbol: curr.token } }
          }, {}) || {}
        state.allowedTokens = {
          ...state.allowedTokens,
          ...allowedTokens,
        }
      })
      .addCase(fetchAllowedTokensBalance.fulfilled, (state, action) => {
        const { position, balances, allowedTokens } = action.payload
        if (Object.prototype.hasOwnProperty.call(state.allowedTokens, position)) {
          state.allowedTokens[position] = {
            ...state.allowedTokens[position],
            balances:
              balances.reduce((acc, curr, index) => {
                return { ...acc, [Object.keys(state.allowedTokens[position].tokens)[index]]: curr }
              }, {}) || {},
          }
        } else {
          state.allowedTokens[position] = {
            tokens: {},
            balances:
              balances.reduce((acc, curr, index) => {
                return { ...acc, [allowedTokens[index].symbol]: balances[index] }
              }, {}) || {},
          }
        }
      })
  },
})

export const { updateCollateralRatio, updatePrepaidInterestCoverage, updateCurrentMintToken } = mintTokenSlice.actions

export default mintTokenSlice.reducer
