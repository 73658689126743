import React from 'react'
import Svg from '../Svg'
import {SvgProps} from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M10 0.375C4.68477 0.375 0.375 4.68477 0.375 10C0.375 15.3152 4.68477 19.625 10 19.625C15.3152 19.625 19.625 15.3152 19.625 10C19.625 4.68477 15.3152 0.375 10 0.375ZM14.1572 6.85684L9.63262 13.1303C9.56938 13.2185 9.48601 13.2905 9.38943 13.3401C9.29285 13.3897 9.18583 13.4156 9.07725 13.4156C8.96866 13.4156 8.86165 13.3897 8.76506 13.3401C8.66848 13.2905 8.58511 13.2185 8.52188 13.1303L5.84277 9.41777C5.76113 9.30391 5.84277 9.14492 5.98242 9.14492H6.99004C7.20918 9.14492 7.41758 9.2502 7.54648 9.43066L9.07617 11.5533L12.4535 6.86973C12.5824 6.69141 12.7887 6.58398 13.01 6.58398H14.0176C14.1572 6.58398 14.2389 6.74297 14.1572 6.85684V6.85684Z"
        fill="#36D4D9"/>
    </Svg>
  )
}

export default Icon
